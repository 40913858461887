import { ENDPOINTS } from '@package/sdk/src/api/endpoints';
import { OfferMapper } from '@package/sdk/src/api/offers/offer';
import { ApiOffer } from '@package/sdk/src/api/offers/offer-types';
import { Offer } from '@package/sdk/src/api/offers/types/offer';
import { SubscriptionMapper } from '@package/sdk/src/api/subscriptions/subscription';
import { ApiSubscription } from '@package/sdk/src/api/subscriptions/subscription-types';
import { Subscription } from '@package/sdk/src/api/subscriptions/types/subscription';
import { SubscriptionPlatform } from '@package/sdk/src/api/subscriptions/types/subscription-platform';

import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';

export interface OfferInfoResponse {
  frontend_text_lists: {
    offers_payment_screen_bullets?: string[];
    offers_general_bullets?: string[];
  };
  frontend_texts: {
    offers_bottom_text?: string;
    offers_payment_screen_title?: string;
    offers_screen_title?: string;
  };
  platform: string;
  slug: string;
}

export interface OffersInfo {
  bullets: string[];
  screenTitle?: string;
  paymentTitle?: string;
  bottomText?: string;
  slug: string;
}

export class OffersService {
  constructor(private readonly $http: RequestService) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public async fetchOffers(bonusId?: string): Promise<Offer[]> {
    const { data } = await this.$http.request<ApiOffer[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.OFFERS_V1,
        query: {
          user_bonus_id: bonusId,
        },
      },
      { transformResult: true, canAbort: false, withToken: true, skipTokenValidation: true },
    );

    return OfferMapper.mapMany(data)
      .filter((offer) => [SubscriptionPlatform.Cloudpayments, SubscriptionPlatform.Payture].includes(offer.platform))
      .slice(0, 3);
  }

  public async fetchOffersInfo(): Promise<OffersInfo> {
    try {
      const { data } = await this.$http.request<OfferInfoResponse>(
        {
          method: HTTPRequestMethod.Get,
          url: ENDPOINTS.PAGES_FRONTEND_GENERAL_INFO_LK,
        },
        { transformResult: true },
      );

      return {
        bullets:
          data.frontend_text_lists.offers_payment_screen_bullets ||
          data.frontend_text_lists.offers_general_bullets ||
          [],
        screenTitle: data.frontend_texts.offers_payment_screen_title || data.frontend_texts.offers_screen_title,
        slug: data.slug,
      };
    } catch (e) {
      return {
        bullets: [],
        screenTitle: '',
        slug: '',
      };
    }
  }

  public async fetchOfferInfo(): Promise<OffersInfo> {
    try {
      const { data } = await this.$http.request<OfferInfoResponse>(
        {
          method: HTTPRequestMethod.Get,
          url: ENDPOINTS.PAGES_FRONTEND_PAYMENT_SCREEN,
        },
        { withToken: true, transformResult: true },
      );

      return {
        bullets:
          data.frontend_text_lists.offers_payment_screen_bullets ||
          data.frontend_text_lists.offers_general_bullets ||
          [],
        paymentTitle: data.frontend_texts.offers_payment_screen_title,
        bottomText: data.frontend_texts.offers_bottom_text,
        slug: data.slug,
      };
    } catch (e) {
      return {
        bullets: [],
        screenTitle: '',
        slug: '',
      };
    }
  }

  public async toggleAutoRenew(subId: string): Promise<Subscription> {
    const { data } = await this.$http.request<ApiSubscription>(
      {
        method: HTTPRequestMethod.Patch,
        params: {
          id: subId,
        },
        url: ENDPOINTS.SUBSCRIPTION_TOGGLE_AUTO_RENEW,
      },
      { withToken: true, transformResult: true },
    );

    return SubscriptionMapper.map(data);
  }
}
