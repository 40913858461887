<template>
  <NavigatableItem ref="el" :class="classNames" />
</template>

<script setup lang="ts">
import { type Direction } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { computed, h, inject, onMounted, ref, useAttrs, useCssModule, useSlots, watch } from 'vue';

interface Props {
  tag?: any;
  activeClass?: string;
  focusKey?: string;
  autofocus?: boolean;
  propParentFocusKey?: string;
  focusBoundaryDirections?: Direction[];
  isFocusBoundary?: boolean;
  hasGlobalAccess?: boolean;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onLongPress?: () => void;
}

const {
  tag = 'div',
  activeClass = '',
  focusKey,
  autofocus,
  propParentFocusKey,
  focusBoundaryDirections,
  isFocusBoundary,
  hasGlobalAccess,
  onClick,
  onFocus,
  onBlur,
  onLongPress,
} = defineProps<Props>();

const updateLayoutOnFocus = inject('updateLayoutOnFocus', true);

const { el, focused, focusSelf } = useNavigatable({
  focusable: true,
  trackChildren: true,
  updateLayoutOnFocus,
  autoRestoreFocus: true,
  focusKey,
  hasGlobalAccess,
  isFocusBoundary,
  parentFocusKey: propParentFocusKey,
  focusBoundaryDirections,
  preferredChildFocusKey: null,
  onLongPress,
  onEnterPress: () => {
    focusSelf();
    if (onClick) {
      onClick();
    }
  },
  onEnterRelease: () => {},
  onArrowPress: () => true,
  onFocus: () => {
    if (onFocus) {
      onFocus();
    }
  },
  onBlur: () => {
    if (onBlur) {
      onBlur();
    }
  },
});

const attrs = useAttrs();
const slots = useSlots();

const style = useCssModule();

const emit = defineEmits<{
  (event: 'active', el?: HTMLElement): void;
  (event: 'inactive', el?: HTMLElement): void;
  (event: 'mounted', el?: HTMLElement): void;
}>();

const isActive = ref(false);
const element = computed<HTMLElement>(() => el.value);

const setActiveCurrentItem = (active: boolean) => {
  if (isActive.value && !active) {
    emit('inactive', element.value);
  }

  isActive.value = active;

  if (!active) {
    return;
  }

  emit('active', element.value);
};

const classNames = computed(() => ({
  [style.navigatable]: true,
  [style.active]: !activeClass && isActive.value,
  [activeClass]: activeClass && isActive.value,
}));

onMounted(() => {
  if (autofocus) {
    focusSelf();
  }
});

watch(
  () => focused.value,
  (value) => {
    setActiveCurrentItem(value);
  },
  { immediate: true },
);

const NavigatableItem = computed(() =>
  h(
    tag,
    {
      'data-navigatable-active': isActive.value,
      isFocused: focused.value,
      ...attrs,
    },
    slots,
  ),
);
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.navigatable {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    content: '';
  }

  &.active::after {
    box-shadow: inset 0 0 0 adjustPx(7px) var(--color-bg-accent);
  }
}

.highlight {
  box-shadow: inset 0 0 0 2px var(--color-text-negative);

  &::after {
    box-shadow: inset 0 0 0 2px var(--color-text-negative);
  }
}
</style>
