import useLogger from '@package/logger/src/use-logger';
import { ApplicationError, EventEmitter } from '@package/sdk/src/core';
import { isServer } from '@PLAYER/player/base/dom';
import { ExternalEventMap } from '@PLAYER/player/modules/event/use-safe-external-event-bus';

export enum PerformanceEvent {
  Seeking = 'seeking',
  DevicePerformance = 'device-performance',
}

interface PerformanceMarkOptions {
  event: PerformanceEvent;
  executionTimeMs: number;
  metadata?: Record<string, string>;
}

const logger = useLogger('performance');

export function isSchedulerAPISupported() {
  if (isServer) {
    return false;
  }

  return Reflect.has(window, 'scheduler');
}

abstract class ExternalPlayerError extends ApplicationError {
  public abstract readonly fatal: boolean;
}

export class BadPlayerPerformanceError extends ExternalPlayerError {
  public readonly name = 'BadPlayerPerformanceError';
  public readonly fatal = false;

  constructor(
    public readonly eventName: PerformanceEvent,
    public readonly metadata: Record<string, string | number> = {},
  ) {
    super(`BAD performance detected. EventName: ${eventName}. Data error - ${JSON.stringify(metadata)}`);
  }

  public toJSON() {
    const { eventName, metadata } = this;

    return {
      eventName,
      ...metadata,
    };
  }
}

export function setPerformanceMark(externalEventBus: EventEmitter<ExternalEventMap>, options: PerformanceMarkOptions) {
  const { executionTimeMs, event } = options;

  if (executionTimeMs > 10000) {
    externalEventBus.emit(
      'error',
      new BadPlayerPerformanceError(event, {
        executionTimeMs,
      }),
    );
  }

  logger.info(options);
}
