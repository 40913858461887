<template>
  <section :class="$style.metadataSection">
    <h2 :class="$style.metadataTitle">{{ title }}</h2>
    <div :class="$style.metadataTags">
      <NavigatableItem
        v-for="(tag, index) in tags"
        :key="tag.slug"
        :class="$style.metadataTag"
        :active-class="$style.active"
        :tag="AppButton"
        :text="tag.title"
        @active="(element) => emit('activated', (element as HTMLElement).offsetTop, index + startIndex)"
        @click="emit('selected', { [filterType]: tag.slug })"
      >
        {{ tag }}
      </NavigatableItem>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { Country, Genre } from '@package/sdk/src/api';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

interface Props {
  title: string;
  filterType: 'genre' | 'country';
  tags: Country[] | Genre[];
  startIndex: number;
}

defineProps<Props>();
const emit = defineEmits<{
  (event: 'activated', offset: number, index: number): void;
  (event: 'selected', options: { genre?: string; country?: string }): void;
}>();
</script>

<style module lang="scss">
@import '@package/ui/src/styles/smarttv-fonts';
@import '@/styles/mixins';

.metadataSection {
  display: flex;
  width: 100%;
  max-width: adjustPx(504px);
  flex-direction: column;
  justify-content: flex-start;
}

.metadataTitle {
  @include SmartTvSubtitle-1();
}

.metadataTags {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: adjustPx(32px);
  line-height: 1;
  margin-top: adjustPx(24px);
  text-align: center;
  flex-wrap: wrap;
}

.metadataTag {
  padding: adjustPx(24px);
  margin-right: adjustPx(24px);
  margin-bottom: adjustPx(24px);
  border-radius: adjustPx(16px);
  border: none;
  background-color: rgba(63, 80, 86, 1);
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  align-self: stretch;
  cursor: pointer;
}
</style>
