<template>
  <section ref="container" :class="$style.wrapper">
    <h2 :class="$style.title">{{ title }}</h2>

    <div ref="collectionsContainer">
      <ScrollViewport tag="ul" :class="$style.collections" :x="offsetCollectionsLeftPx" role="list">
        <li v-for="(item, index) in items" :key="item.id" :class="$style.collection">
          <NavigatableItem
            :class="$style.collection"
            :tag="AppButton"
            :data-testid="`collections-${index + 1}`"
            :data-index="index"
            :text="item.title"
            @active="(element) => handleActiveCollection(element as HTMLElement)"
            @click="onSelectCollection(index)"
          />
        </li>
      </ScrollViewport>
    </div>

    <div ref="listContainer">
      <ScrollViewport tag="ul" :class="$style.list" :x="offsetPostersLeftPx" role="list">
        <li v-for="(content, index) in currentCollection?.contents" :key="content.id" :class="$style.item">
          <NavigatableItem
            :class="$style.link"
            :tag="AppSlotButton"
            :data-testid="`collection-${index + 1}`"
            :data-index="index"
            @active="(element) => handleActiveContent(element as HTMLElement)"
            @click="
              openContentPage({
                contentType: content.contentType,
                id: content.id,
                title: content.title,
                titlesSelectionName: content.title,
                titlesSelectionPosition: index,
                from: ItemPageFrom.Compilation,
              })
            "
          >
            <app-image :src="content.poster" :class="$style.preview" :width="550" />
          </NavigatableItem>

          <section :class="$style.subtitle">
            <p>{{ content.title }}</p>
          </section>
        </li>
      </ScrollViewport>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ItemPageFrom } from '@package/sdk/src/analytics';
import type { Compilation, Media } from '@package/sdk/src/api';
import { useMediaContentActions, useSliderOffset } from '@SMART/index';
import { computed, ref } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

interface Props {
  items: Compilation[];
  title: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'activated', offset: number): void;
  (event: 'clicked', item: Media): void;
}>();

const { openContentPage } = useMediaContentActions();
const { handleUpdateOffset: handleUpdateCollectionsOffset, offsetLeftPx: offsetCollectionsLeftPx } = useSliderOffset();
const { handleUpdateOffset: handleUpdateContentOffset, offsetLeftPx: offsetPostersLeftPx } = useSliderOffset();

const container = ref<HTMLElement>();
const collectionsContainer = ref<HTMLElement>();
const listContainer = ref<HTMLElement>();
const activeSeason = ref(0);

const currentCollection = computed(() => props.items[activeSeason.value]);

const handleActiveCollection = (element: HTMLElement) => {
  handleUpdateCollectionsOffset(
    '$el' in element ? (element.$el as HTMLElement) : element,
    collectionsContainer.value!.offsetWidth,
  );
  emit('activated', container.value?.offsetTop || 0);
};

const handleActiveContent = (element: HTMLElement) => {
  handleUpdateContentOffset(
    '$el' in element ? (element.$el as HTMLElement) : element,
    collectionsContainer.value!.offsetWidth,
  );
  emit('activated', container.value?.offsetTop || 0);
};

const onSelectCollection = (index: number) => (activeSeason.value = index);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.title {
  margin-bottom: adjust.adjustPx(24px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.list {
  display: flex;
  height: 200px;
}

.item {
  position: relative;
  display: flex;
  align-items: center;

  width: adjust.adjustPx(376px);
  min-width: adjust.adjustPx(376px);
  height: adjust.adjustPx(508px);
  min-height: adjust.adjustPx(508px);
  border-radius: var(--g-border-round-24);
  background-color: var(--color-bg-tertiary);
  margin-right: adjust.adjustPx(32px);
  outline: none;
  justify-content: center;

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }

  .subtitle {
    position: absolute;
    top: adjust.adjustPx(516px);
    left: adjust.adjustPx(8px);

    display: -webkit-box;
    width: adjust.adjustPx(376px);

    overflow: hidden;
    color: var(--color-notheme-text-primary);
    text-align: start;
    min-width: adjust.adjustPx(376px);
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @include smartTvFonts.SmartTvBody-1();
  }
}

.collections {
  display: flex;
  height: adjust.adjustPx(112px);
}
.collection {
  height: adjust.adjustPx(80px);
  min-height: adjust.adjustPx(80px);
  margin-right: var(--g-spacing-12);
  border-radius: var(--g-border-round-16);
}

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  border-radius: var(--g-border-round-24);
  overflow: hidden;
  outline: none;
  justify-content: center;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--g-border-round-24);
    content: '';
  }

  &:hover::after,
  &.active::after {
    box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-bg-accent);
  }
}
</style>
