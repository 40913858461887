<template>
  <div ref="el" :class="{ [$style.playButtonSmartTv]: true, [$style.active]: focused }">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { AnyFunction } from '@PLAYER/player/base/function';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';

const props = defineProps<{
  onClick?: AnyFunction;
  onFocus?: AnyFunction;
  onBlur?: AnyFunction;
}>();

const { useNavigatable } = useSmartNavigatable();

const { el, focused, focusSelf } = useNavigatable({
  onEnterPress: () => {
    focusSelf();
    if (props.onClick) {
      props.onClick();
    }
  },
  onFocus: () => {
    if (props.onFocus) {
      props.onFocus();
    }
  },
  onBlur: () => {
    if (props.onBlur) {
      props.onBlur();
    }
  },
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;

.playButtonSmartTv {
  padding: adjust.adjustPx(9px);
  border-radius: var(--g-border-round-12);
  background-color: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);
  @include smartTvFonts.SmartTvLabel-2();
}

.active {
  background-color: var(--color-notheme-bg-accent);
  outline: initial;

  button {
    color: var(--color-notheme-text-accent);
  }
}
</style>
