export function getChromeVersion(userAgent = navigator.userAgent) {
  const raw = userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : false;
}

if (import.meta.vitest) {
  const webosUserAgent = {
    'webOS TV 24':
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.5359.211 Safari/537.36 WebAppManager',
    'webOS TV 23':
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.128 Safari/537.36 WebAppManager',
    'webOS TV 22':
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.88 Safari/537.36 WebAppManager',
    'webOS TV 6.x':
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.79 Safari/537.36 WebAppManager',
    'webOS TV 5.x':
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/68.0.3440.106 Safari/537.36 WebAppManager',
    'webOS TV 4.x':
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/53.0.2785.34 Safari/537.36 WebAppManager',
    'webOS TV 3.x':
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) QtWebEngine/5.2.1 Chrome/38.0.2125.122 Safari/537.36 WebAppManager',
  };

  const { it, expect } = import.meta.vitest;

  it('WebOS TV 24 return 108', () => {
    expect(getChromeVersion(webosUserAgent['webOS TV 24'])).toBe(108);
  });

  it('WebOS TV 23 return 94', () => {
    expect(getChromeVersion(webosUserAgent['webOS TV 23'])).toBe(94);
  });

  it('WebOS TV 22 return 87', () => {
    expect(getChromeVersion(webosUserAgent['webOS TV 22'])).toBe(87);
  });

  it('WebOS TV 6.x return 79', () => {
    expect(getChromeVersion(webosUserAgent['webOS TV 6.x'])).toBe(79);
  });

  it('WebOS TV 5.x return 68', () => {
    expect(getChromeVersion(webosUserAgent['webOS TV 5.x'])).toBe(68);
  });

  it('WebOS TV 4.x return 53', () => {
    expect(getChromeVersion(webosUserAgent['webOS TV 4.x'])).toBe(53);
  });

  it('WebOS TV 3.x return 38', () => {
    expect(getChromeVersion(webosUserAgent['webOS TV 3.x'])).toBe(38);
  });
}
