import { PlayerFocusKeys } from './focus-keys';

export default function useSmartNavigatable() {
  if (!window.$smarttv) {
    return new Proxy(
      {
        useNavigatable: () => ({
          el: null,
          focused: null,
          focusKey: null,
          focusSelf: () => {},
        }),
        SpatialNavigation: null,
        PlayerFocusKeys,
      },
      {},
    );
  }

  return { ...window.$smarttv, PlayerFocusKeys };
}
