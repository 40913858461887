<template></template>

<script lang="ts" setup>
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { DisposableStore, isDefined } from '@package/sdk/src/core';
import { isTouchDevice } from '@PLAYER/player/base/dom';
import defineGlobalVueProperty from '@PLAYER/player/modules/global/define-global-vue-property';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { PlayerGlobalProperty } from '@PLAYER/player/modules/instance/interfaces';
import useMouseHandler from '@PLAYER/player/modules/mouse/use-mouse-handler';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useSafeVideoEventHandler from '@PLAYER/player/modules/video/use-safe-video-event-handler';
import { watchOnce } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, getCurrentInstance, nextTick, watchEffect } from 'vue';

const props = defineProps<{
  requestVideoControls: VoidFunction;
  releaseVideoControls: VoidFunction;
  requestMyChannelVideoStateControls: VoidFunction;
  releaseMyChannelVideoStateControls: VoidFunction;
}>();

const { isSmartTV, isWeb } = usePlatform();
const { isFocused, isLinkedKinomShown } = storeToRefs(useLayoutStore());
const { isControlsVisible, isMyChannelVideoStateControlsVisible, isPointerOverVideoControls } =
  storeToRefs(useVideoControlsStore());
const disposables = new DisposableStore();
const videoEventHandler = useSafeVideoEventHandler();

const mouse = useMouseHandler();
const app = getCurrentInstance();

const isWebTouchDevice = isWeb && isTouchDevice;

const releaseVideoControlDelay = computed(() => {
  if (isLinkedKinomShown.value) {
    return ConstantsConfigPlayer.getProperty('linkedKinomShowTimeoutMs');
  }

  if (isSmartTV) {
    return ConstantsConfigPlayer.getProperty('controlsVisibleSmartTvTimeoutMs');
  }

  return ConstantsConfigPlayer.getProperty('controlsVisibleWebDefaultTimeoutMs');
});

let timeoutId: number | undefined;

const updateActiveState = () => {
  if (isDefined(timeoutId)) {
    window.clearTimeout(timeoutId);
  } else {
    Reflect.apply(props.requestVideoControls, undefined, []);
    Reflect.apply(props.requestMyChannelVideoStateControls, undefined, []);
  }

  if (isWeb && isPointerOverVideoControls.value) {
    return;
  }

  timeoutId = window.setTimeout(() => {
    Reflect.apply(props.releaseVideoControls, undefined, []);
    Reflect.apply(props.releaseMyChannelVideoStateControls, undefined, []);

    timeoutId = undefined;
  }, releaseVideoControlDelay.value);
};

defineGlobalVueProperty(app, PlayerGlobalProperty.Touch, updateActiveState);

const onKeydown = () => {
  if (isWeb && !isFocused.value) {
    return;
  }

  updateActiveState();
};

const onWheel = () => () => {
  if (isFocused.value) {
    return;
  }

  updateActiveState();
};

watchOnce(isLinkedKinomShown, (val) => {
  if (val) {
    updateActiveState();
  }
});

watchEffect(() => {
  if (isWebTouchDevice && isControlsVisible.value && isMyChannelVideoStateControlsVisible.value) {
    updateActiveState();
  }
});

useEffect(() => {
  window.addEventListener('keydown', onKeydown);
  window.addEventListener('wheel', onWheel);

  // Отслеживание мышки мы делаем только для вебовский версии
  // в SmartTV это не делаем
  if (isWeb) {
    if (isTouchDevice) {
      disposables.add(mouse.on('touchend', updateActiveState));
    } else {
      disposables.add(mouse.on('mousedown', updateActiveState));
      disposables.add(mouse.on('mousemove', updateActiveState));
      disposables.add(mouse.on('mouseover', updateActiveState));
    }
  }

  if (isSmartTV) {
    const handler = videoEventHandler.addEventListener('loadedmetadata', () => {
      updateActiveState();
      handler.dispose();
    });
  } else {
    nextTick(() => updateActiveState());
  }

  return () => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    window.removeEventListener('wheel', onWheel);
    window.removeEventListener('keydown', onKeydown);
    disposables.dispose();
  };
});
</script>
