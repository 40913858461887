<template>
  <UIModal>
    <section ref="el" :class="$style.modal">
      <article :class="$style.illustration">
        <div :class="[$style.border, $style.borderLeft]"></div>
        <div :class="$style.slides">
          <img :class="$style.image" data-image :src="slider1" />
          <img data-image :class="[$style.image, $style.margin]" :width="250" :src="slider2" />
          <img data-image :class="$style.image" :src="slider3" />
          <img data-image :class="$style.image" :src="slider4" />
        </div>

        <div :class="$style.frame">
          <div :class="$style.frameSlides">
            <img data-image :class="$style.image" :src="slider2" />
            <img data-image :class="$style.image" :src="slider3" />
          </div>
        </div>
        <div :class="[$style.border, $style.borderRight]"></div>
      </article>
      <article :class="$style.text">
        <h3 :class="$style.title">{{ $t('pages.mediaCard.promo.title') }}</h3>
        <h4 :class="$style.subtitle">{{ $t('pages.mediaCard.promo.subtitle') }}</h4>
        <section :class="$style.buttons">
          <NavigatableItem
            :tag="AppButton"
            :class="$style.button"
            :active-class="$style.active"
            :text="primaryButtonText"
            :focus-key="FocusKeys.PROMO_BUTTON"
            @click="onGotoOffers"
          />
          <NavigatableItem
            :tag="AppButton"
            :class="$style.button"
            :active-class="$style.active"
            :text="$t('pages.mediaCard.promo.cancel')"
            @click="onFinish"
          />
        </section>
        <h4 :class="$style.hint">{{ $t('pages.mediaCard.promo.hint') }}</h4>
      </article>
    </section>
  </UIModal>
</template>

<script setup lang="ts">
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import BgImage1 from '@SMART/assets/images/my-channel-slider-1.webp';
import BgImage2 from '@SMART/assets/images/my-channel-slider-2.webp';
import BgImage3 from '@SMART/assets/images/my-channel-slider-3.webp';
import BgImage4 from '@SMART/assets/images/my-channel-slider-4.webp';
import {
  customEventsService,
  FocusKeys,
  getPlayButtonText,
  onboardingService,
  RouterPage,
  routerService,
  SessionGetters,
  SessionState,
  storeToRefs,
  useSessionStore,
} from '@SMART/index';
import { computed, onMounted, provide } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import UIModal from '@/components/modal/UIModal.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

interface Props {
  images?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  images: () => [],
});

const emit = defineEmits<{
  (e: 'finish'): void;
}>();

const { el, focusKey, focusSelf } = useNavigatable({ focusKey: FocusKeys.PROMO_SUB_MODAL, isFocusBoundary: true });
provide('parentFocusKey', focusKey.value);

const slider1 = computed(() => props.images[0] || BgImage1);
const slider2 = computed(() => props.images[1] || BgImage2);
const slider3 = computed(() => props.images[2] || BgImage3);
const slider4 = computed(() => props.images[3] || BgImage4);

const { isActiveSubscription, isPartnerSubscription, isPartnerUser, subscription, currentOffer, hasTrialOffer } =
  storeToRefs<SessionState, SessionGetters, unknown>(useSessionStore());

const { isAuth } = useSessionVariables();

const primaryButtonText = computed(() => {
  const episodeData = {
    season: 0,
    episode: 0,
  };

  return getPlayButtonText({
    isAuth: isAuth.value,
    isActiveSubscription: isActiveSubscription.value,
    isPartnerSubscription: isPartnerSubscription.value || isPartnerUser.value,
    canContinueWatch: false,
    isVOD: true,
    isLive: false,
    isWatchButtonWithContentTypeText: true,
    offer: currentOffer.value,
    hasTrialOffer: hasTrialOffer.value,
    subscription: subscription.value,
    episodeData,
  });
});

const onFinish = () => {
  onboardingService.finishMyChannel();
  emit('finish');
};

const onGotoOffers = () => {
  routerService.push({ name: RouterPage.Offers });
};

onMounted(() => {
  customEventsService.setOnPressBackCallback(() => onFinish(), true);
  focusSelf();
});
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@package/ui/src/styles/smarttv-fonts';

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: adjustPx(206px) adjustPx(48px) adjustPx(206px) 0px;
  background: var(--color-bg-primary);
}
.illustration {
  display: flex;
  align-items: center;
  width: adjustPx(960px);
  height: adjustPx(668px);
  margin-right: adjustPx(48px);
  overflow: hidden;

  .border {
    position: absolute;
    z-index: 10;
    width: adjustPx(96px);
    height: adjustPx(668px);
    background: var(--gradient-content);

    &Left {
      left: 0;
    }

    &Right {
      left: adjustPx(864px);
    }
  }

  .slides {
    position: fixed;
    top: adjustPx(438px);
    left: adjustPx(120px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: adjustPx(768px);

    .image {
      width: adjustPx(336px);
      height: adjustPx(216px);
      margin-right: adjustPx(18px);
      object-fit: cover;
      border-radius: adjustPx(36px);
      opacity: 0.6;
      -webkit-filter: blur(3px);
      filter: blur(3px);
    }

    .margin {
      margin-right: adjustPx(120px);
    }
  }

  .frame {
    position: fixed;
    top: adjustPx(360px);
    left: adjustPx(198px);
    display: flex;
    align-items: center;
    width: adjustPx(540px);
    height: adjustPx(360px);
    border-radius: adjustPx(90px);
    // background: rgba(196, 196, 196, 0.01);
    background: var(--color-bg-primary);
    overflow: hidden;

    box-shadow:
      0px 15.068px 67.805px 0px rgba(193, 114, 206, 0.3) inset,
      0px 369.16px 376.694px -180.813px rgba(0, 199, 178, 0.1) inset,
      0px -308.889px 256.152px -241.084px rgba(155, 205, 233, 0.3) inset,
      0px 21px 39px -12px #fff inset,
      0px 146.911px 210.949px -135.61px rgba(255, 255, 255, 0.4) inset;

    &Icon {
      position: absolute;
      top: adjustPx(102px);
      left: adjustPx(192px);
      width: adjustPx(156px);
      height: adjustPx(156px);
    }

    &Slides {
      position: absolute;
      left: adjustPx(-200px);
      display: flex;
      align-items: center;
      width: adjustPx(540px);
      height: adjustPx(360px);
      border-radius: adjustPx(90px);

      .image {
        width: adjustPx(390px);
        height: adjustPx(252px);
        margin-right: adjustPx(120px);
        object-fit: cover;
        border-radius: adjustPx(36px);
        scale: 1.1;
      }
    }
  }
}

.text {
  position: fixed;
  top: adjustPx(316px);
  left: adjustPx(960px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: adjustPx(864px);
  height: adjustPx(668px);
  background: var(--color-bg-primary);

  .title {
    margin-bottom: adjustPx(32px);

    @include SmartTvTitle-3();
  }

  .subtitle {
    margin-bottom: adjustPx(64px);

    @include SmartTvBody-2();
  }

  .buttons {
    display: flex;

    .button {
      height: adjustPx(96px);
      border-radius: adjustPx(24px);
      border: none;
      margin-right: adjustPx(18px);

      @include SmartTvLabel-2();
    }

    .active {
      background: var(--color-notheme-bg-accent);
      color: var(--color-notheme-text-accent);
    }
  }

  .hint {
    margin-top: adjustPx(64px);

    @include SmartTvBody-3();
  }
}
</style>
