<template>
  <div ref="el">
    <div v-if="content" :class="$style.wrapper">
      <KinomPlayer v-if="isPlayerShown" :moments="moments" :content="content" />

      <div :class="$style.contentWrapper">
        <ScrollViewport tag="ul" :class="$style.list" :y="offsetTopPx" role="list" @vue:mounted="onVNodeMounted">
          <li :class="$style.header">
            <MediaCardTopHeader
              :title="content.title"
              :logo="content.logo"
              :subtitle="contentSubtitle"
              :limit="limit"
              :subtitleGenres="subtitleGenres"
              :percent="content.contentMatchPercent"
              :description="content.tagline"
              @vue:mounted="onMediaCardTopHeaderMounted"
            />
          </li>
          <li>
            <PlayButtonSubtitle />
            <div :class="$style.controls">
              <PlayButton
                should-disable-content-when-unavailable
                with-access-kind
                :focus-key="FocusKeys.PLAY_BUTTON"
                @active="onScroll(0)"
              />

              <NavigatableItem
                :class="{
                  [$style.save]: true,
                  [$style.selected]: isAddedToCollection,
                }"
                :active-class="$style.saveActive"
                :tag="AppButton"
                :focus-key="FocusKeys.BOOKMARK_BUTTON"
                @click="
                  isAddedToCollection
                    ? onRemoveCollectionItem(content.id ?? '')
                    : onSaveCollectionItem(content.id ?? '')
                "
                @active="onSaveActive(true)"
                @inactive="onSaveActive(false)"
              >
                <template #icon>
                  <BookmarkFillIcon v-if="isAddedToCollection" :class="$style.bookMarkSavedIcon" />
                  <BookmarkIcon v-else />
                </template>
              </NavigatableItem>
            </div>
          </li>
          <li
            v-if="content.seasons?.length && isMediaCardTopHeaderMounted"
            :class="{ [$style.moments]: true, [$style.bgPrimary]: isSliderActive }"
          >
            <MediaCardSeasons
              type="moment"
              :items="content.seasons"
              :title="$t('pages.mediaCard.seasons')"
              @activated="onScroll"
              @selected="onPlayEpisode"
            />
          </li>

          <li
            v-if="moments.length && isMediaCardTopHeaderMounted"
            :class="{ [$style.moments]: true, [$style.bgPrimary]: isSliderActive }"
          >
            <MediaCardSlider
              type="moment"
              :items="moments"
              :title="$t('pages.mediaCard.moments')"
              @activated="onScroll"
              @selected="onMomentSelect"
            />
          </li>

          <li :class="{ [$style.section]: true, [$style.details]: true, [$style.bgPrimary]: isSliderActive }">
            <MediaCardDetails
              :content="content"
              @activated="(x) => onScrollDetails(x - OFFSET_ABOUT)"
              @selected="onDetailsSelect"
            />
          </li>
          <!-- todo:  waiting for API -->
          <!-- <li v-if="persons.length" :class="$style.section">
          <MediaCardSlider
            type="person"
            :items="persons"
            :title="$t('pages.mediaCard.persons')"
            @activated="onScroll"
          />
        </li> -->
          <li :class="$style.section">
            <MediaCardSlider
              type="poster"
              :class="$style.similar"
              :items="similar"
              :title="$t('pages.mediaCard.similar')"
              @activated="onScroll"
              @selected="onSimilarSelect"
            />
          </li>
          <li v-if="collections?.length" :class="$style.section">
            <MediaCardCollectionsSlider
              type="poster"
              :class="$style.similar"
              :items="collections"
              :title="$t(`pages.mediaCard.inCollections-${content.contentType}`)"
              @activated="onScroll"
              @selected="onSimilarSelect"
            />
          </li>
          <li :class="$style.section1">
            <section :class="$style.linkToTop">
              <NavigatableItem
                :class="$style.button"
                :tag="AppButton"
                :text="$t('pages.mediaCard.toTop')"
                @click="onNavToTop"
              ></NavigatableItem>
            </section>
          </li>
        </ScrollViewport>
      </div>
      <PromoSubModal v-if="shouldShowPromoSub" :images="background" @finish="finishPromoSub" />
    </div>
  </div>
</template>

<script setup lang="ts">
import * as playerHelpers from '@package/media-player/src/player/helpers';
import { AnalyticPageName, useContentPageAnalytics } from '@package/sdk/src/analytics';
import { ContentAccessTypes, Episode, Media, MediaContentType, Serial } from '@package/sdk/src/api';
import { timeout, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import BookmarkIcon from '@SMART/assets/icons/51x51/bookmark.svg';
import BookmarkFillIcon from '@SMART/assets/icons/51x51/save.svg';
import {
  AlertMessageTypes,
  alertService,
  analyticService,
  collectionService,
  ContentActionsWithoutStoreArgument,
  ContentGetters,
  ContentState,
  FocusKeys,
  RouterPage,
  routerService,
  SessionGetters,
  SessionState,
  storeToRefs,
  translate,
  useCatalogStore,
  useContentStore,
  useMediaContentActions,
  useOfferActions,
  useSessionStore,
} from '@SMART/index';
import { computed, onMounted, onUnmounted, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import AppButton from '@/components/app-button/AppButton.vue';
import PromoSubModal from '@/components/promo-sub-modal/PromoSubModal.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import KinomPlayer from '@/pages/media-card/components/KinomPlayer.vue';
import MediaCardDetails from '@/pages/media-card/components/MediaCardDetails.vue';
import MediaCardSeasons from '@/pages/media-card/components/MediaCardSeasons.vue';
import MediaCardSlider, { SelectedMediaPayload } from '@/pages/media-card/components/MediaCardSlider.vue';
import MediaCardTopHeader from '@/pages/media-card/components/MediaCardTopHeader.vue';
import PlayButton from '@/pages/media-card/components/PlayButton.vue';
import PlayButtonSubtitle from '@/pages/media-card/components/PlayButtonSubtitle.vue';

import MediaCardCollectionsSlider from './components/MediaCardCollectionsSlider.vue';

const OFFSET_ABOUT = 50;

const contentStore = useContentStore();
const { content, contentSubtitle, moments, similar, limit, subtitleGenres, collections, isAboutToFinish } = storeToRefs<
  ContentState,
  ContentGetters,
  ContentActionsWithoutStoreArgument
>(contentStore);

const route = useRoute();
const { isAvailable, isUnavailableSoon } = playerHelpers.useContentAvailability();
const { openContentPage, openPlayerPage } = useMediaContentActions();
const { isActiveSubscription, isPartnerSubscription } = storeToRefs<SessionState, SessionGetters, unknown>(
  useSessionStore(),
);
const { openOffersPage } = useOfferActions();

const catalogStore = useCatalogStore();

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.MEDIA_CARD_PAGE,
  preferredChildFocusKey: FocusKeys.BOOKMARK_BUTTON,
  isFocusBoundary: true,
  focusBoundaryDirections: ['down'],
});
provide('parentFocusKey', focusKey.value);

const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({ withTimeout: true });
const { isVNodeMounted: isMediaCardTopHeaderMounted, onVNodeMounted: onMediaCardTopHeaderMounted } = useVNodeMounted({
  withTimeout: true,
});

let offsetDetailsPx: number;

const offsetTopPx = ref(0);
const isSliderActive = ref(false);
const shouldShowPromoSub = ref(isAboutToFinish.value);

const finishPromoSub = () => {
  shouldShowPromoSub.value = false;
  focusSelf();
};

const onScroll = (offset: number = 0) => {
  offsetTopPx.value = offset;
  isSliderActive.value = Boolean(offset);
};

const onScrollDetails = (offset: number = 0) => {
  if (!offsetDetailsPx) {
    offsetDetailsPx = offset;
  }
  offsetTopPx.value = offsetDetailsPx;
  isSliderActive.value = true;
};

const onMomentSelect = ({ index }: SelectedMediaPayload) => {
  return routerService.push({
    name: RouterPage.MomentsPage,
    params: {
      page: 1,
      size: 27,
      id: content.value.id,
      type: 'content',
      momentIndex: index,
    },
  });
};

const onPlayEpisode = (episode: Episode, episodeIndex: number, activeSeasonId: number) => {
  const { id: episodeId, accessKind } = episode;
  const { id } = content.value;
  const isFreeEpisode = accessKind === ContentAccessTypes.AllUsers;

  if (!isActiveSubscription.value && (isPartnerSubscription.value || !isFreeEpisode)) {
    return openOffersPage();
  }

  const isContentAvailable = isAvailable(episode) || isUnavailableSoon(episode);

  if (!isContentAvailable) {
    return;
  }

  return openPlayerPage({
    id,
    contentType: content.value.contentType,
    episodeId,
    seasonIndex: String(activeSeasonId),
    episodeIndex: String(episodeIndex),
  });
};

const onDetailsSelect = (options: { genre?: string; country?: string }) => {
  return routerService.push({
    name: RouterPage.CatalogPage,
    query: {
      genre: options.genre,
      country: options.country,
      contentType: content.value.contentType,
    },
  });
};

const onSimilarSelect = (payload: SelectedMediaPayload) => {
  const item = payload.item as Media;
  openContentPage({ contentType: item.contentType, id: item.id, title: item.title });
};

// todo:  waiting for API
// const persons = computed(() =>
//   content.value?.actors.concat(content.value?.directors.map((director) => ({ ...director, role: 'director' }))),
// );

const isAddedToCollection = ref(
  content.value?.inUserCollection ||
    collectionService.savedFilmsItems.includes(route.params.id as string) ||
    collectionService.savedSerialsItems.includes(route.params.id as string),
);

const isContentAvailable = computed(() => isAvailable(content.value) || isUnavailableSoon(content.value));
const background = computed(() => (content.value as Serial).seasons?.map((season) => season.background));

const shouldShowHelp = ref(false);

const saveToCollectionKey = computed(() =>
  isAddedToCollection.value ? 'pages.mediaCard.removeFromCollection' : 'pages.mediaCard.saveToCollection',
);

const isPlayerShown = computed(() => !isSliderActive.value && isVNodeMounted.value);

const onSaveActive = (value: boolean) => {
  shouldShowHelp.value = value;
  onScroll(0);
};

const onSaveCollectionItem = async (id: string) => {
  if (!content.value) {
    throw new UnexpectedComponentStateError('content.value');
  }

  try {
    await collectionService.saveItems([id], content.value.contentType);

    isAddedToCollection.value = true;
    // update in order to reload user collection
    catalogStore.setUpdated(true);

    alertService.addAlert({
      message: translate('pages.mediaCard.addedToCollection'),
      type: AlertMessageTypes.Success,
    });
  } catch (e) {
    alertService.addAlert({ message: translate(e.message), type: AlertMessageTypes.Warning });
  }
};

const onRemoveCollectionItem = async (id: string) => {
  if (!content.value) {
    throw new UnexpectedComponentStateError('content.value');
  }

  // update in order to reload user collection
  catalogStore.setUpdated(true);
  await collectionService.removeItems([id], content.value.contentType);
  isAddedToCollection.value = false;

  alertService.addAlert({
    message: translate('pages.mediaCard.removedFromCollection'),
    type: AlertMessageTypes.Success,
  });
};

const onNavToTop = () => {
  SpatialNavigation.setFocus(FocusKeys.BOOKMARK_BUTTON);
  onScroll(0);
};

watch(
  () => content.value,
  () => {
    if (!shouldShowPromoSub.value) {
      focusSelf();
    }
  },
);

const contentPageAnalytics = useContentPageAnalytics(analyticService.sender);

const fetchContent = async () => {
  const { id, type } = route.params as { id: string; type: MediaContentType };

  await collectionService.updateSavedItems();

  if (!id || !type) {
    throw new UnexpectedComponentStateError('params');
  }

  await Promise.all([
    await contentStore.fetchContent({ id, type }),
    await contentStore.fetchMoments(id),
    await contentStore.fetchCollections(id),
    await contentStore.fetchSimilar(id),
  ]);

  if (!content.value) {
    throw new UnexpectedComponentStateError('content');
  }
};

const onLoadError = async (error: unknown) => {
  if (error) {
    await timeout(250);
    return routerService.replace({ name: RouterPage.MainPage });
  }
};

onUnmounted(() => {
  contentStore.resetContentData();
});

onMounted(async () => {
  try {
    await fetchContent();

    const { title, contentType, id } = content.value as Media;

    contentPageAnalytics.onShowItemPage({
      title,
      contentType,
      itemId: id,
      page: AnalyticPageName.Item,
    });

    isAddedToCollection.value =
      content.value?.inUserCollection ||
      collectionService.savedFilmsItems.includes(route.params.id as string) ||
      collectionService.savedSerialsItems.includes(route.params.id as string);
  } catch (error) {
    if (route.name === RouterPage.MainPage) {
      return onLoadError(error);
    }
  } finally {
    focusSelf();
  }
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@import '@/styles/mixins';
@import '@/styles/layers';

.wrapper {
  overflow: hidden;
  padding-top: adjust.adjustPx(78px);
  padding-left: adjust.adjustPx(140px);

  &::before {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: var(--gradient-to-bottom);
    content: '';
  }
  &::after {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background: var(--gradient-to-left);
    content: '';
  }
}

.header {
  margin-bottom: adjust.adjustPx(48px);
}

.contentWrapper {
  margin-left: adjust.adjustPx(60px);
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-content);
}

.bgPrimary {
  background-color: var(--color-bg-primary);
}

.moments {
  padding-top: adjust.adjustPx(160px);
}

.details {
  padding-top: adjust.adjustPx(175px);
}

.section {
  min-height: adjust.adjustPx(720px);
  padding-top: adjust.adjustPx(120px);
}

.similar {
  min-height: adjust.adjustPx(720px);
  padding-bottom: adjust.adjustPx(184px);
}

.linkToTop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin-top: adjust.adjustPx(100px);

  .button {
    width: adjust.adjustPx(325px);
    height: adjust.adjustPx(88px);
    border-radius: var(--g-border-round-16);
    outline: none;
  }
}

.controls {
  display: flex;
  margin-top: adjust.adjustPx(24px);

  button span {
    margin-bottom: 0;
  }
}

.list {
  position: relative;
  overflow: hidden;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  padding: adjust.adjustPx(10px) adjust.adjustPx(20px);
  width: adjust.adjustPx(515px);
  color: var(--color-notheme-white-100);
  min-height: adjust.adjustPx(73px);
  margin-bottom: adjust.adjustPx(4px);
  outline: none;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: adjust.adjustPx(16px);
    content: '';
  }
}

.icon {
  width: adjust.adjustPx(38px);
  height: adjust.adjustPx(38px);
  margin-right: adjust.adjustPx(24px);
}

.save {
  display: flex;
  align-items: center;
  padding: adjust.adjustPx(20px) adjust.adjustPx(23px);
  width: adjust.adjustPx(88px);
  height: adjust.adjustPx(88px);
  border-radius: adjust.adjustPx(16px);
  margin-left: adjust.adjustPx(20px);

  svg {
    display: inline-flex;
    width: adjust.adjustPx(34px);
    height: adjust.adjustPx(34px);

    fill: var(--color-text-primary);
  }

  .bookMarkSavedIcon {
    width: adjust.adjustPx(51px);
    height: adjust.adjustPx(51px);

    path {
      fill: var(--color-text-primary);
      stroke: var(--color-text-primary);
    }
  }

  &Active {
    background-color: var(--color-bg-accent);

    svg {
      fill: var(--color-notheme-text-accent);
    }

    .bookMarkSavedIcon {
      path {
        fill: var(--color-notheme-text-accent);
        stroke: var(--color-notheme-text-accent);
      }
    }
  }

  &:hover {
    background: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);
    path {
      fill: var(--color-notheme-text-accent);
      stroke: var(--color-notheme-text-accent);
    }
  }
}

.selected {
  &:hover {
    background-color: var(--color-bg-accent);
    svg {
      fill: var(--color-notheme-text-accent);
    }
  }
}
</style>
