<template>
  <article :class="$style.movieDetails">
    <section :class="$style.movieInfo">
      <div :class="$style.movieText">
        <h1 :class="$style.movieTitle">{{ $t(`pages.mediaCard.about-${content.contentType}`) }}</h1>
        <p :class="$style.movieDescription">
          {{ content.description }}
        </p>
      </div>
      <div :class="$style.movieDetailsGrid">
        <div :class="$style.infoItem">
          <MediaCardInfoItem
            v-if="content.titleEn || content.title"
            :title="$t('pages.mediaCard.originalTitle')"
            :value="content.titleEn || content.title"
          />
          <MediaCardInfoItem v-if="content.year" :title="$t('pages.mediaCard.productionYear')" :value="content.year" />
        </div>
        <div :class="$style.infoItem">
          <MediaCardInfoItem v-if="duration" :title="$t('pages.mediaCard.duration')" :value="duration" />
          <MediaCardInfoItem v-if="director" :title="$t('pages.mediaCard.director')" :value="director" />
        </div>
      </div>
      <div :class="$style.movieDetailsGrid">
        <MediaCardInfoItem v-if="actors" :title="$t('pages.mediaCard.actors')" :value="actors" />
      </div>
    </section>
    <aside :class="$style.movieMetadata">
      <MediaCardMetadataSection
        v-if="content.genres.length"
        filter-type="genre"
        :title="$t('pages.mediaCard.genres')"
        :tags="content.genres"
        :startIndex="0"
        @activated="(offsetTop, index) => emit('activated', offsetTop, index)"
        @selected="(options) => emit('selected', options)"
      />
      <MediaCardMetadataSection
        v-if="content.countries.length"
        filter-type="country"
        :title="$t('pages.mediaCard.countries')"
        :tags="content.countries"
        :startIndex="content.genres.length"
        @activated="(offsetTop, index) => emit('activated', offsetTop, index)"
        @selected="(options) => emit('selected', options)"
      />
    </aside>
  </article>
</template>

<script lang="ts" setup>
import { Media } from '@package/sdk/src/api';
import { formatDuration } from '@SMART/index';
import { computed } from 'vue';

import MediaCardInfoItem from './MediaCardInfoItem.vue';
import MediaCardMetadataSection from './MediaCardMetadataSection.vue';

interface Props {
  content: Media;
}
const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'activated', offset: number, index: number): void;
  (event: 'selected', options: { genre?: string; country?: string }): void;
}>();

const duration = computed(() => formatDuration(props.content?.duration, 'hhmm'));
const director = computed(() => props.content?.directors?.[0]?.name);
const actors = computed(() =>
  props.content?.actors
    ?.slice(0, 3)
    ?.map((x) => x.name)
    .join(', '),
);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@package/ui/src/styles/smarttv-fonts';
@import '@/styles/mixins';

.movieDetails {
  display: flex;
  align-items: flex-start;
  padding-right: adjust.adjustPx(60px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.movieInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  margin-right: adjust.adjustPx(56px);
  width: adjust.adjustPx(1011px);
  min-width: adjust.adjustPx(240px);
}

.movieText {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--color-text-primary);
}

.movieTitle {
  font-weight: 500;
  font-size: adjust.adjustPx(48px);
  line-height: 1;
  letter-spacing: adjust.adjustPx(-0.48px);
}

.movieDescription {
  margin-top: adjust.adjustPx(24px);

  @include smartTvFonts.SmartTvBody-2();
}

.movieDetailsGrid {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-weight: 400;
  font-size: adjust.adjustPx(32px);
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-top: adjust.adjustPx(40px);
  }
}

.infoItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  flex-basis: 0;
  margin-right: adjust.adjustPx(24px);
  min-width: adjust.adjustPx(240px);
}

.movieMetadata {
  display: flex;
  width: adjust.adjustPx(403px);
  font-weight: 500;
  color: var(--color-text-primary);
  flex-direction: column;
  flex-grow: 1;
  min-width: adjust.adjustPx(240px);
}
</style>
