import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import { LocalStorageKey, LocalStorageValueMap } from '@PLAYER/player/base/local-storage';
import useDeviceBattery from '@PLAYER/player/modules/battery/use-device-battery';
import { computed } from 'vue';

export default function useTransitionName() {
  const localStorage = useLocalStorage<LocalStorageKey, LocalStorageValueMap>();
  const { isLowBatteryMode } = useDeviceBattery();

  const transitionName = computed(() => {
    const isLowBatteryModeEnabled = localStorage.getValue(LocalStorageKey.LowBatteryMode, false)?.value;

    if (!isLowBatteryModeEnabled) {
      return 'fade';
    }

    return isLowBatteryMode.value ? '' : 'fade';
  });

  return {
    transitionName,
  };
}
