<template>
  <div ref="el" :class="$style.wrapper">
    <AppMenu v-if="!isMenuHidden" ref="appMenuRef" :key="menuKey" />
    <AppBuildBadge v-if="isBuildBadgeShown" />

    <div :class="{ [$style.pageMenuExpanded]: hasFocusedMenu, [$style.page]: true }">
      <RouterView v-slot="{ Component }">
        <KeepAlive
          :key="cacheKey"
          :data-key="cacheKey"
          :include="[
            $RouterPage.CatalogPage,
            $RouterPage.CollectionPage,
            $RouterPage.SearchPage,
            $RouterPage.ChannelsPage,
            $RouterPage.MediaCardSimilarPage,
            $RouterPage.MyChannelPage,
          ]"
          :max="1"
        >
          <component :is="Component" />
        </KeepAlive>
      </RouterView>
    </div>

    <UIAlert />

    <FullScreenModal v-if="exitModalShown">
      <AppExitModal translation-page="modals.exit" @close="exitModalResolve(false)" @exit="exitModalResolve(true)" />
    </FullScreenModal>
  </div>
</template>

<script setup lang="ts">
import useLogger from '@package/logger/src/use-logger';
import { SmartTvVijuPlayer } from '@package/media-player/src/player';
import { FeatureToggle, Profile } from '@package/sdk/src/api';
import { isDefined, isUndefined } from '@package/sdk/src/core';
import * as Sentry from '@sentry/vue';
import type { SessionGetters, SessionState } from '@SMART/index';
import {
  analyticService,
  channelsService,
  contentCacheManager,
  deviceService,
  environmentService,
  featuresService,
  initStore,
  RouterPage,
  routerService,
  storeToRefs,
  useContentStore,
  useMainPageStore,
  useSessionStore,
  useTvChannelsStore,
} from '@SMART/index';
import { nanoid } from 'nanoid';
import { v4 as uuidv4 } from 'uuid';
import { computed, onErrorCaptured, ref, useTemplateRef, watch } from 'vue';
import { useRoute } from 'vue-router';

import AppBuildBadge from '@/components/app-build-badge/AppBuildBadge.vue';
import AppMenu from '@/components/menu/AppMenu.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

import UIAlert from './components/alert/UIAlert.vue';
import AppExitModal from './components/modal/AppExitModal.vue';
import FullScreenModal from './components/modal/FullScreenModal.vue';

type ResolveFunction = (value: boolean) => void;

const route = useRoute();
const store = initStore();

const contentStore = useContentStore();
const sessionStore = useSessionStore();
const tvChannelsStore = useTvChannelsStore();
const mainPageStore = useMainPageStore();

const logger = useLogger('App.vue');

const { currentOffer, _profile, _user } = storeToRefs<SessionState, SessionGetters, unknown>(sessionStore);
const { isAuth } = useSessionVariables();

const isMenuHidden = ref(false);
const cacheKey = ref(uuidv4());

const routeQuery = ref('{}');
const routeParams = ref('{}');

const isReleaseMode = environmentService.getVariable<boolean>('isRelease');

const menuRef = useTemplateRef<{
  hasFocusedChild: boolean;
}>('appMenuRef');
const hasFocusedMenu = computed(() => Boolean(menuRef.value?.hasFocusedChild));

// Перед релизом на прод, будем скрывать для прода - оставлять для остальных
const isBuildBadgeShown = computed(() => {
  if (!isReleaseMode) {
    return true;
  }

  return route.path.includes('settings') || route.path.includes('auth');
});

const hiddenAppMenuRoutes = [
  RouterPage.MediaCardPlayerPage,
  RouterPage.ChannelsPlayerPage,
  RouterPage.ProfilesPage,
  RouterPage.Offers,
  RouterPage.ParentalPage,
];

// const routesWithoutOptionsCheck = [RouterPage.CatalogPage, RouterPage.SearchPage, RouterPage.MainPage];

// const cachedRoutes = [
//   RouterPage.CatalogPage,
//   RouterPage.CollectionPage,
//   RouterPage.SearchPage,
//   RouterPage.ChannelsPage,
//   RouterPage.ChannelsPlayerPage,
//   RouterPage.Offers,
//   RouterPage.OfferInfo,
//   RouterPage.MyChannelPage,
//   RouterPage.AuthPage,
//   RouterPage.MediaCardSimilarPage,
//   RouterPage.MediaCardPage,
// ];

const pathsToCache = [
  { to: RouterPage.CatalogPage, from: RouterPage.MediaCardPage },
  { to: RouterPage.SearchPage, from: RouterPage.MediaCardPage },
  { to: RouterPage.CollectionPage, from: RouterPage.MediaCardPage },
  { to: RouterPage.MainPage, from: RouterPage.MediaCardPage },
];

const resetSessionData = () => {
  contentStore.reset();
  tvChannelsStore.reset();
  contentCacheManager.clear();
  mainPageStore.reset();

  channelsService.fetchChannels();
  contentStore.fetchGenres();
  contentStore.fetchPeriods();
  contentStore.fetchCountries();
  sessionStore.fetchOffers();

  cacheKey.value = uuidv4();
};

const onDidProfileUpdated = (oldProfile: Profile, newProfile: Profile) => {
  const routeName = route.name;

  if (isUndefined(oldProfile) && isUndefined(newProfile)) {
    return;
  }

  /**
   * Сессия может появится при смене пароля, там мы НЕ сбрасываем все, чтобы страница не слетела
   */
  if (routeName === RouterPage.AuthPage) {
    return;
  }

  resetSessionData();

  analyticService.setFeatureFlags({
    [FeatureToggle.AllPlatformTestAA]: featuresService.getFeatureFlag(FeatureToggle.AllPlatformTestAA)?.variant?.value,
  });

  SmartTvVijuPlayer.setSession({ offer: currentOffer.value, user: _user?.value });
};

const onBeforeEach = (to?: RouterPage, from?: RouterPage) => {
  if (isDefined(to) && hiddenAppMenuRoutes.includes(to)) {
    isMenuHidden.value = true;

    return;
  }

  const shouldCache = Boolean(
    pathsToCache.find((path) => {
      if (path.to && path.from) {
        return (to === path.to && from === path.from) || (from === path.to && to === path.from);
      }

      return path.from ? path.from === from : path.to === to;
    }),
  );

  if (!shouldCache) {
    cacheKey.value = uuidv4();
  }

  isMenuHidden.value = false;
};

const menuKey = computed(() => _user?.value?.id ?? nanoid(3));

watch(() => route.name as RouterPage, onBeforeEach, { immediate: true });

watch(
  () => route.params,
  (params) => {
    const paramsString = JSON.stringify(params);
    if (
      routerService.lastVisitedRoute?.name === route.name &&
      routeParams.value !== paramsString
      // !routesWithoutOptionsCheck.includes(routerService.lastVisitedRoute?.name as RouterPage)
    ) {
      routeParams.value = paramsString;
      cacheKey.value = uuidv4();
    }
  },
);
watch(
  () => route.query,
  (query) => {
    const queryString = JSON.stringify(query);
    if (routerService.lastVisitedRoute?.name === route.name && routeQuery.value !== queryString) {
      routeQuery.value = queryString;
      cacheKey.value = uuidv4();
    }
  },
);
watch(() => _profile?.value, onDidProfileUpdated, { immediate: true });

const exitModalShown = ref(false);
const exitModalResolve = ref<ResolveFunction>(() => {});

const showAppExitModal = async (): Promise<boolean> => {
  exitModalShown.value = true;

  return new Promise((res) => {
    exitModalResolve.value = (value: boolean) => {
      exitModalShown.value = false;

      if (value) {
        deviceService.exit();
      }

      res(value);
    };
  });
};

window.$exports = { showAppExitModal };

// Скорей всего, если мы оказались тут - то у нас крашнулось приложение
onErrorCaptured((error) => {
  logger.error(error?.toString());
  logger.error(error?.stack as string);

  Sentry.captureException(error);

  throw error;
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.page {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.pageMenuExpanded {
  left: adjust.adjustPx(406px);
}
</style>
