<template>
  <div ref="el" class="page-container" :class="$style.page">
    <section :class="$style.search">
      <span ref="anchorRef"></span>

      <transition v-show="!isKeyboardHidden" name="fade">
        <NewVirtualKeyboard
          ref="keyboardRef"
          v-model:input="input"
          :keyboards="searchKeyboard"
          :placeholder="$t('pages.search.placeholder')"
          :upper-case="isKeyboardUppercase"
          :symbols-upper-case="true"
          :debounce-ms="150"
          input-type="text"
          input-variant="secondary"
          @vue:mounted="onVNodeMounted"
          @active="onActivateKeyboard"
        />
      </transition>

      <section v-if="isSearchContentShown" v-show="!isKeyboardHidden" ref="historyRef" :class="$style.history">
        <NavigatableItem
          v-for="item in history"
          :key="item.createdAt"
          :prop-parent-focus-key="FocusKeys.VIRTUAL_KEYBOARD"
          :tag="AppButton"
          variation="smart-button-secondary"
          :class="$style.historyButton"
          :active-class="$style.active"
          :text="item.query"
          :text-class-name="$style.text"
          @active="onActivateHistory"
          @click="onHistorySelect(item.query)"
        />
      </section>
    </section>

    <search-content-page
      v-if="isSearchContentShown"
      :input="input"
      :history="history"
      :is-keyboard-hidden="isKeyboardHidden"
      :is-skeleton-shown="shouldShowSkeleton"
      :is-input-empty="isInputEmpty"
      @update:is-search-active="(val: boolean) => (isSearchActive = val)"
      @update:is-input-empty="(val: boolean) => (isInputEmpty = val)"
      @history:fetch="onHistoryFetch"
      @keyboard:hide="onKeyboardHide"
    />
  </div>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/smart-tv-constants-instance';
import { useSearchPageAnalytics } from '@package/sdk/src/analytics';
import { debounce, DisposableStore } from '@package/sdk/src/core';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import type { History } from '@SMART/index';
import {
  analyticService,
  catalogService,
  customEventsService,
  FocusKeys,
  searchService,
  useMediaContentActions,
} from '@SMART/index';
import { computed, onBeforeUnmount, onDeactivated, onMounted, provide, Ref, ref, useTemplateRef, watch } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import SearchContentPage from '@/components/search/SearchContentPage.vue';
import SkeletonButtons from '@/components/skeletons/SkeletonButtons.vue';
import { getSearchKeyboard } from '@/components/virtual-keyboard/keyboards';
import NewVirtualKeyboard from '@/components/virtual-keyboard/NewVirtualKeyboard.vue';
import { ContentRowItem } from '@/components/virtual-scroll/VirtualScroll.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

const anchorRef = ref();

const disposableStore = new DisposableStore();
const searchPageAnalytics = useSearchPageAnalytics(analyticService.sender);
const { isAuth } = useSessionVariables();

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.SEARCH_PAGE,
  preferredChildFocusKey: FocusKeys.VIRTUAL_KEYBOARD,
  forceFocus: true,
});
provide('parentFocusKey', focusKey.value);

const { isVNodeMounted: isSearchContentShown, onVNodeMounted } = useVNodeMounted({ withTimeout: true, timeout: 1000 });

const input = ref('');
const shouldUpdateContent = ref(true);
const isSearchActive = ref(true);

const shouldShowSkeleton = computed(() => isSearchActive.value);
const isInputEmpty = ref(true);

const search = debounce(() => {
  shouldUpdateContent.value = true;
}, ConstantsConfigInstanceSmartTV.getProperty('debounceTimeoutModernMs'));

watch(input, async () => {
  if (!input.value?.length) {
    keyboardRef.value.setUpperCase(true);
    isSearchActive.value = false;
    isInputEmpty.value = true;
    return;
  }

  keyboardRef.value.setUpperCase(false);
  searchPageAnalytics.onSearchRequestEntering(input.value);

  isSearchActive.value = true;
  isInputEmpty.value = false;

  search();
});

// Keyboard
const keyboardRef = ref();
const isKeyboardHidden = ref(false);
const isKeyboardUppercase = ref(true);

const onClear = (request: string) => {
  searchPageAnalytics.onClickSearchRequestCancel(request);
};

const searchKeyboard = getSearchKeyboard({ input, keyboardRef, onClear });

function onKeyboardHide() {
  isKeyboardHidden.value = true;
}

async function onActivateKeyboard() {
  if (!isKeyboardHidden.value) {
    return;
  }

  isKeyboardHidden.value = false;
  customEventsService.setWheelAction({ dec: 'up', inc: 'down' });

  window.requestAnimationFrame(() => {
    anchorRef.value?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  });

  if (input.value) {
    SpatialNavigation.setFocus(FocusKeys.KEYBOARD_KEY(FocusKeys.CLEAR));
  }
}

const history = ref<History[]>([]);
const historyRef = ref();

const onActivateHistory = () => {
  isKeyboardHidden.value = false;
};

const onHistorySelect = (query: string) => (input.value = query);

const onHistoryFetch = async () => {
  if (!isAuth.value) {
    return;
  }

  history.value = await searchService.getSearchHistory({ page: 1, perPage: 5 });
};

onDeactivated(() => {
  customEventsService.setWheelAction({ dec: 'up', inc: 'down' });
});

onMounted(async () => {
  if (isAuth.value) {
    history.value = await searchService.getSearchHistory({ page: 1, perPage: 5 });
  }

  searchPageAnalytics.onShowSearchPage();

  focusSelf();
});

onBeforeUnmount(() => {
  disposableStore.dispose();
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';

.page {
  .title {
    @include f-subtitle-2;
  }
}

.search {
  display: flex;
  flex-flow: row;
  margin-top: adjust.adjustPx(20px);
  margin-bottom: adjust.adjustPx(40px);
  margin-left: adjust.adjustPx(45px);
}

.history {
  display: flex;
  flex-flow: column;

  margin-top: adjust.adjustPx(113.5px);
  margin-left: adjust.adjustPx(20px);
  overflow: hidden;

  &Button {
    margin-bottom: adjust.adjustPx(8px);
    width: adjust.adjustPx(521px);
    max-height: adjust.adjustPx(68px);
    border-radius: adjust.adjustPx(16px);
    border: none;
    background-color: transparent;
    justify-content: flex-start;
    min-height: adjust.adjustPx(68px);
  }

  .active {
    background-color: var(--color-bg-accent);
  }

  .text {
    display: block;
    width: 100%;
    text-align: start;

    @include f-body;
  }
}
</style>
