<template>
  <li v-for="(item, index) in items" ref="el" :key="index" :class="{ [$style.contentRow]: true }">
    <slot :item="item" :index="index"></slot>
  </li>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import type { ContentRowItem } from '@/components/virtual-scroll/VirtualScroll.vue';

defineProps<{
  items: ContentRowItem[];
}>();

const el = ref<HTMLElement>();

defineExpose({
  el,
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.contentRow {
  display: flex;
  margin-bottom: adjust.adjustPx(16px);
}
</style>
