<template>
  <section ref="el" role="tablist" :class="$style.header">
    <div :class="$style.content">
      <div :class="$style.contentContainer">
        <UITabs :class="$style.tabs" :tab="contentTab">
          <template #tabs>
            <NavigatableItem
              :class="$style.tabsItem"
              :tag="UITab"
              :disabled="disabled"
              :selected="moviesSelected"
              :active-class="$style.active"
              @click="onTabSelected(MediaContentType.Movie)"
            >
              <span :class="$style.text">{{ $t('pages.catalog.filmsTab') }}</span>
            </NavigatableItem>
            <NavigatableItem
              :class="{ [$style.tabsItem]: true, [$style.showDivider]: contentTab === 2 }"
              :tag="UITab"
              :disabled="disabled"
              :selected="serialsSelected"
              :active-class="$style.active"
              @click="onTabSelected(MediaContentType.Serial)"
            >
              <span :class="$style.text">{{ $t('pages.catalog.serialsTab') }}</span>
            </NavigatableItem>
            <NavigatableItem
              :class="{ [$style.tabsItem]: true, [$style.showDivider]: contentTab === 0 }"
              :tag="UITab"
              :disabled="disabled"
              :selected="allSelected"
              :active-class="$style.active"
              @click="onTabSelected(MediaContentType.All)"
            >
              <span :class="$style.text">{{ $t('pages.catalog.allTab') }}</span>
            </NavigatableItem>
          </template>
        </UITabs>

        <NavigatableItem
          :focus-key="FocusKeys.CATALOG_FILTERS_BUTTON"
          :tag="AppButton"
          :disabled="disabled"
          :active-class="$style.active"
          navigation-key="filters"
          :text="$t('pages.catalog.filters')"
          @click="onFiltersOpen"
        >
          <template #icon>
            <IconFilters :class="$style.icon" />
          </template>
        </NavigatableItem>
      </div>

      <NavigatableItem
        :focus-key="FocusKeys.CATALOG_SHUFFLE_BUTTON"
        :tag="AppButton"
        :disabled="disabled"
        :class="$style.shuffle"
        :active-class="$style.active"
        navigation-key="shuffle"
        :text="$t('pages.catalog.contentShuffle')"
        @click="onSelectShuffle"
      >
        <template #icon>
          <IconShuffle
            :class="{
              [$style.icon]: true,
              [$style.iconDisabled]: disabled,
            }"
          />
        </template>
      </NavigatableItem>
    </div>

    <div v-if="filterItems.length" :class="$style.filters">
      <NavigatableItem
        :tag="AppButton"
        :disabled="disabled"
        :active-class="$style.active"
        :class="{ [$style.resetFilters]: true, [$style.scrolled]: isFiltersScrolled }"
        :text="$t('pages.catalog.resetFilters')"
        @click="resetFilters"
      >
        <template #icon>
          <IconDeleteOutlined
            :class="{
              [$style.icon]: true,
              [$style.iconDisabled]: disabled,
            }"
          />
        </template>
      </NavigatableItem>

      <div :class="$style.filtersScrollWrapper">
        <ScrollViewport ref="filtersScroll" :class="$style.filterItems" orientation="horizontal" role="list">
          <NavigatableItem
            v-for="(filter, index) in filterItems"
            :key="`filter-${index}`"
            :focus-key="FocusKeys.CATALOG_FILTERS_SLIDER_ITEM(index)"
            :tag="AppButton"
            :disabled="disabled"
            :class="$style.filterItem"
            :active-class="$style.filterItemActive"
            :text="filter.title"
            @active="onFilterSelect"
            @click="onFilterToggle(filter)"
          >
            <template #icon-right>
              <IconClose :class="$style.filterItemIcon" />
            </template>
          </NavigatableItem>
        </ScrollViewport>
      </div>
    </div>

    <FiltersModal
      v-if="isFiltersModalVisible"
      :filters="filters"
      @filter:select="onFilterToggle"
      @close="onFilterModalClose"
    />

    <ShuffleModal v-if="isShuffleModalVisible" :content-type="contentType" @close="isShuffleModalVisible = false" />
  </section>
</template>

<script setup lang="ts">
import { Country, FilterItem, Genre, MediaContentType, Year } from '@package/sdk/src/api';
import useListNavigationActions from '@package/smarttv-base/src/navigation/use-list-navigation-actions';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import IconClose from '@SMART/assets/icons/24x24/close.svg';
import IconDeleteOutlined from '@SMART/assets/icons/33x33/delete-outlined.svg';
import IconFilters from '@SMART/assets/icons/40x40/filters.svg';
import IconShuffle from '@SMART/assets/icons/40x40/shuffle.svg';
import {
  adjustPx,
  ContentGetters,
  ContentState,
  FocusKeys,
  scrollToElement,
  storeToRefs,
  useContentStore,
} from '@SMART/index';
import { computed, nextTick, onMounted, provide, reactive, ref, useTemplateRef, watch } from 'vue';
import { useRoute } from 'vue-router';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import UITab from '@/components/tabs/UITab.vue';
import UITabs from '@/components/tabs/UITabs.vue';

import FiltersModal from './FiltersModal.vue';
import ShuffleModal from './ShuffleModal.vue';

export type MappedFilters = typeof filters;

interface Props {
  contentType: MediaContentType;
  disabled?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:type', value: MediaContentType): void;
  (event: 'update:filters', value: typeof selectedFilters.value): void;
}>();

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.CONTENT_SELECT,
});
provide('parentFocusKey', focusKey.value);

const filterList = useListNavigationActions(FocusKeys.CATALOG_FILTERS_SLIDER_ITEM);

const route = useRoute();

// Filters
const contentStore = useContentStore();

const { genres, countries, periods } = storeToRefs<ContentState, ContentGetters, unknown>(contentStore);

const selectedGenresFilters: Set<Genre> = reactive(new Set());
const selectedCountriesFilters: Set<Country> = reactive(new Set());
const selectedPeriodsFilters: Set<Year> = reactive(new Set());

const filtersScroll = useTemplateRef('filtersScroll');
const isFiltersScrolled = ref(false);

const onFilterSelect = (filter: typeof filtersScroll.value) => {
  const offset = filter?.$el?.offsetLeft;

  scrollToElement(filtersScroll?.value?.$el, { left: offset - adjustPx(60) });
  isFiltersScrolled.value = Boolean(offset);
};

const isFiltersModalVisible = ref(false);

const initFilter = function <T>(array: T[]) {
  return array.map((item) => {
    return {
      ...item,
      selected: false,
    };
  });
};

const filters = reactive({
  genres: initFilter(genres.value),
  countries: initFilter(countries.value),
  periods: initFilter(periods.value),
});

const selectedFilters = computed(() => {
  return {
    genres: Array.from(selectedGenresFilters.values()),
    countries: Array.from(selectedCountriesFilters.values()),
    periods: Array.from(selectedPeriodsFilters.values()),
  };
});

const filterItems = computed(() => {
  return [
    ...Array.from(selectedGenresFilters.values()),
    ...Array.from(selectedCountriesFilters.values()),
    ...Array.from(selectedPeriodsFilters.values()),
  ];
});

const onFilterToggle = (item: FilterItem) => {
  let target = null;
  switch (item.filterType) {
    case 'genre':
      target = filters.genres.find((f) => f.id === (item as Genre).id);

      if (target) {
        if (selectedGenresFilters.has(item as Genre)) {
          selectedGenresFilters.delete(item as Genre);
          target.selected = false;
        } else {
          selectedGenresFilters.add(item as Genre);
          target.selected = true;
        }
      }
      break;
    case 'country':
      target = filters.countries.find((f) => f.code === (item as Country).code);

      if (target) {
        if (selectedCountriesFilters.has(item as Country)) {
          selectedCountriesFilters.delete(item as Country);
          target.selected = false;
        } else {
          selectedCountriesFilters.add(item as Country);
          target.selected = true;
        }
      }
      break;
    case 'year':
      target = filters.periods.find((f) => f.id === (item as Year).id);

      if (target) {
        if (selectedPeriodsFilters.has(item as Year)) {
          selectedPeriodsFilters.delete(item as Year);
          target.selected = false;
        } else {
          selectedPeriodsFilters.add(item as Year);
          target.selected = true;
        }
      }
      break;
  }

  if (filterList.isFocused()) {
    filterList.handleDelete();
  }

  emit('update:filters', selectedFilters.value);
};

const resetFilters = async () => {
  selectedGenresFilters.clear();
  selectedCountriesFilters.clear();
  selectedPeriodsFilters.clear();

  filters.genres = initFilter(genres.value);
  filters.countries = initFilter(countries.value);
  filters.periods = initFilter(periods.value);

  focusSelf();

  emit('update:filters', selectedFilters.value);
};

const onFilterModalClose = async () => {
  isFiltersModalVisible.value = false;
  await nextTick();
  if (SpatialNavigation.doesFocusableExist(FocusKeys.CATALOG_RESET_FILTERS_EMPTY_CONTENT)) {
    SpatialNavigation.setFocus(FocusKeys.CATALOG_RESET_FILTERS_EMPTY_CONTENT);
  } else {
    SpatialNavigation.setFocus(FocusKeys.CATALOG_FILTERS_BUTTON);
  }
};

const contentTab = ref(0);

const contentType = computed({
  get: () => props.contentType,
  set: (value) => value && emit('update:type', value),
});

const moviesSelected = computed(() => contentType.value === MediaContentType.Movie);
const serialsSelected = computed(() => contentType.value === MediaContentType.Serial);
const allSelected = computed(() => contentType.value === MediaContentType.All);

const onTabSelected = (type: MediaContentType) => {
  contentType.value = type;

  switch (type) {
    case MediaContentType.Movie:
      contentTab.value = 0;
      break;
    case MediaContentType.Serial:
      contentTab.value = 1;
      break;
    case MediaContentType.All:
      contentTab.value = 2;
      break;
  }
};

const isShuffleModalVisible = ref(false);

const onSelectShuffle = () => {
  isShuffleModalVisible.value = true;
  isFiltersModalVisible.value = false;
};

const onFiltersOpen = () => {
  isFiltersModalVisible.value = true;
  isShuffleModalVisible.value = false;
};

defineExpose({
  filterItems,
  selectedFilters,
  resetFilters,
  isFiltersModalVisible,
  isShuffleModalVisible,
});

onMounted(() => {
  if (route.query.contentType && typeof route.query.contentType === 'string') {
    onTabSelected(route.query.contentType as MediaContentType);
  }

  const queryGenre = route.query.genre as string[];
  const queryCountry = route.query.country as string[];

  const genreArray = Array.isArray(queryGenre) ? queryGenre : [queryGenre];
  const countryArray = Array.isArray(queryCountry) ? queryCountry : [queryCountry];

  filters.genres.forEach((genre) => {
    if (genreArray.includes(genre.slug ?? '')) {
      genre.selected = true;
      selectedGenresFilters.add(genre);
    }
  });

  filters.countries.forEach((country) => {
    if (countryArray.includes(country.slug ?? '')) {
      country.selected = true;
      selectedCountriesFilters.add(country);
    }
  });

  emit('update:filters', selectedFilters.value);
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/layers';

.header {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  .text {
    @include smartTvFonts.SmartTvLabel-2();
  }
}

.content {
  display: flex;
  margin-bottom: adjust.adjustPx(32px);
}

.contentContainer {
  display: flex;
  flex-grow: 1;
}

.tabs {
  margin-right: adjust.adjustPx(24px);
}

.tabsItem {
  width: adjust.adjustPx(174px) !important;
  height: 100%;

  &:not(:first-child) {
    margin-left: adjust.adjustPx(18px);

    &.showDivider::before {
      position: absolute;
      top: 20%;
      left: adjust.adjustPx(-10px);
      display: block;
      width: 2px;
      height: 60%;
      background-color: var(--color-stroke-div-primary);
      content: '';
    }
  }
}

.filters {
  display: flex;
}

.resetFilters {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-content);
  background-color: rgba(87, 92, 94, 1);
  box-shadow: adjust.adjustPx(-400px) 0px 0px adjust.adjustPx(30px) var(--color-bg-primary);
  outline: solid adjust.adjustPx(10px) var(--color-bg-primary);

  &.scrolled::before {
    position: absolute;
    top: adjust.adjustPx(-2px) !important;
    right: adjust.adjustPx(-180px);
    z-index: map-get($map: $layers, $key: --z-index-heading);
    width: adjust.adjustPx(180px);
    height: 110%;
    content: '';
    background-image: url('@SMART/assets/images/shadow-catalog-left.webp');
    background-size: 100% 100%;
  }
}

.filtersScrollWrapper {
  width: calc(100% - adjust.adjustPx(410px) + adjust.adjustPx(20px) + adjust.adjustPx(60px));
}

.filterItems {
  position: relative;
  display: flex;
  margin-left: adjust.adjustPx(20px);
}

@mixin iconActive {
  border-radius: adjust.adjustPx(3px);

  background-color: var(--color-bg-accent);

  color: var(--color-notheme-text-accent);
  transition-duration: 0.3s;
  box-shadow: 0px 0px 0px adjust.adjustPx(16px) var(--color-bg-accent);
}

.filterItem {
  margin-right: adjust.adjustPx(20px);
  background-color: var(--color-notheme-bg-secondary-80) !important;
  color: var(--color-text-primary) !important;
  transition-duration: 0.3s;

  &:hover {
    svg {
      @include iconActive();
    }
  }

  &:last-child {
    margin-right: adjust.adjustPx(60px);
  }
}

.filterItemActive {
  svg {
    @include iconActive();
  }
}

.filterItemIcon {
  margin-left: adjust.adjustPx(24px);
}

.shuffle {
  display: flex;
  align-items: center;
  margin-left: adjust.adjustPx(16px);
}

.icon {
  width: adjust.adjustPx(33px);
  height: adjust.adjustPx(33px);
  margin-right: adjust.adjustPx(10px);
}

.iconDisabled {
  color: var(--color-states-text-disabled);
}

.active {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}
</style>
