import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { Channel, Media } from '@package/sdk/src/api';
import { isMobile, isNavigatorMediaSessionAvailable, isTizenOS, isWebOS } from '@PLAYER/player/base/dom';
import { TimeSeconds } from '@PLAYER/player/base/number';
import { useContentAvailability } from '@PLAYER/player/helpers';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import useExperimentalFeature from '@PLAYER/player/modules/experimental-feature/use-experimental-feature';
import useDVR from '@PLAYER/player/modules/hooks/use-dvr';
import useDetectMediaTechSupport, {
  UseDetectMediaTechSupportResult,
} from '@PLAYER/player/modules/hooks/use-native-playback-support';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useSession from '@PLAYER/player/modules/session/use-session';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

interface Options {
  loop?: boolean;
  muted?: boolean;
  posterSrc?: string;
  autoplay?: boolean;
}

export default function useVideoPlayerVariables(options: Options = {}) {
  const layoutStore = useLayoutStore();

  const {
    isInitialized,
    duration,
    isVideoPreviewShown: isVideoPreviewShownStored,
    displayedCurrentTime,
  } = storeToRefs(useVideoUIStore());
  const { isRewindTipNotificationShown } = storeToRefs(useVideoControlsStore());

  const { contextMenuOptions, isShownLoader, loaderType, isShownPoster, isAdultContentWarningVisible } =
    storeToRefs(layoutStore);

  const { manifestUrl, hasSubtitles, customManifestFragmentStart, customManifestTotalDuration } =
    storeToRefs(useManifestStore());
  const { isDVR } = useDVR();
  const {
    isLazyLoadingInteractionEnabled,
    isTizenAVPlayerAPIEnabled,
    isTataudioAPIEnabled,
    isHlsJsAPIEnabled,
    isShakaPlayerAPIEnabled,
  } = useExperimentalFeature();
  const { isLive, isKinom, isVOD, isMyChannelPlayer, isContentBackgroundPlayer } = useProjector();

  const { content } = useContentInformation();
  const { isAvailable } = useContentAvailability();

  const videoConfig = useVideoConfig();
  const { isAuth, isInactivePartnerSubscription } = useSession();

  const isAutoplaySuccess = ref(false);

  const isNavigatorMediaSessionEnabled = computed(() => {
    if (!isNavigatorMediaSessionAvailable) {
      return false;
    }

    return isLive.value || isVOD.value || isMyChannelPlayer.value;
  });

  const isViewportAutoplayDetectorShown = computed(() => {
    if (!isLazyLoadingInteractionEnabled.value) {
      return false;
    }

    return isKinom.value && !isInitialized.value;
  });

  const isVideoPreviewShown = computed(() => {
    if (!isLazyLoadingInteractionEnabled.value) {
      return false;
    }

    if (!isKinom.value) {
      return false;
    }

    return isVideoPreviewShownStored.value;
  });

  const isContextMenuShown = computed(() => {
    // Для теликов не отображаем контекстное меню. (мышки там все равно нет)
    if (isTizenOS || isWebOS) {
      return false;
    }

    return Boolean(contextMenuOptions.value);
  });

  const isSubtitlesAvailable = computed(() => hasSubtitles.value);

  const isLoaderShown = computed(() => {
    if (isContentBackgroundPlayer.value) {
      return false;
    }

    if (isViewportAutoplayDetectorShown.value) {
      return isShownLoader.value;
    }

    return isShownLoader.value || !isInitialized.value;
  });

  const isCurrentActionViewShown = computed(() => {
    // Для мобилок не отображаем текущее действие
    if (isMobile) {
      return false;
    }

    return isInitialized.value && !isKinom.value;
  });

  const isFaceRecognizerShown = computed(() => false);

  const isPlaybackBackgroundControlsShown = computed(() => {
    if (isMyChannelPlayer.value) {
      return true;
    }

    if (isKinom.value || !isMobile || isRewindTipNotificationShown.value) {
      return false;
    }

    return isInitialized.value;
  });

  const normalizedMuted = computed(() => Boolean(options.muted));

  const normalizedLoop = computed(() => Boolean(options.loop));

  const normalizedPosterSrc = computed(() => videoConfig['video.poster'] || options.posterSrc);

  const isPlayerOpenedInPageMode = computed(() => videoConfig['video.pageMode']);

  const isFreeContent = computed(() => {
    if (isKinom.value) {
      return true;
    }

    const isContentAvailable = isAvailable(content.value as Media);

    if (!isContentAvailable) {
      return false;
    }

    if (isLive.value) {
      return (
        (content.value as Channel).accessKind === 'all_users' ||
        (isAuth.value && (content.value as Channel).accessKind === 'register')
      );
    }

    return isContentAvailable;
  });

  const isAutoplay = computed(() => {
    return Boolean(options.autoplay) && isFreeContent.value;
  });

  const isWelcomeViewShown = computed(() => {
    // Welcome-view теперь показывается ТОЛЬКО для ТВ каналов
    if (isKinom.value || !isLive.value) {
      return false;
    }

    if (isFreeContent.value) {
      return isShownPoster.value && !isAutoplaySuccess.value;
    }

    // Если вдруг это партнерский юзер или нет ссылки на манифевст
    if (!manifestUrl.value || isInactivePartnerSubscription.value) {
      return true;
    }

    return isShownPoster.value && !isAutoplaySuccess.value;
  });

  const mediaSourceTechPlaybackType = computed<UseDetectMediaTechSupportResult>(() => {
    if (!manifestUrl.value) {
      return 'native';
    }

    return useDetectMediaTechSupport(manifestUrl.value, {
      forceTataudio: isTataudioAPIEnabled.value,
      forceAVPlayer: isTizenAVPlayerAPIEnabled.value,
      forceHlsJs: isHlsJsAPIEnabled.value,
      forceShakaPlayer: isShakaPlayerAPIEnabled.value,
    });
  });

  const normalizedDuration = computed(() => {
    if (isLive.value && mediaSourceTechPlaybackType.value === 'hls.js') {
      return customManifestTotalDuration.value;
    }

    return duration.value;
  });

  const normalizedDisplayedCurrentTime = computed(() => {
    const diff = displayedCurrentTime.value - duration.value;

    if (diff > 0) {
      return duration.value;
    }

    if (isLive.value && mediaSourceTechPlaybackType.value === 'hls.js') {
      return displayedCurrentTime.value - customManifestFragmentStart.value;
    }

    return displayedCurrentTime.value;
  });

  const isLiveTranslation = (duration: TimeSeconds, currentTime: TimeSeconds) =>
    duration - currentTime < ConstantsConfigPlayer.getProperty('goToLiveTimeoutEdgeSeconds');

  const isCurrentTimeLive = computed(() =>
    isLiveTranslation(normalizedDuration.value, normalizedDisplayedCurrentTime.value),
  );

  const isLiveWithDVR = computed(() => isLive.value && isDVR.value);
  const isLiveWithoutDVR = computed(() => isLive.value && !isDVR.value);

  return {
    isFreeContent,
    isCurrentTimeLive,
    normalizedDisplayedCurrentTime,
    mediaSourceTechPlaybackType,
    normalizedDuration,
    isSubtitlesAvailable,
    isVideoPreviewShown,
    isViewportAutoplayDetectorShown,
    isAutoplaySuccess,
    isAdultContentWarningVisible,
    isInitialized,
    loaderType,
    isAutoplay,
    isWelcomeViewShown,
    isLoaderShown,
    isFaceRecognizerShown,
    isNavigatorMediaSessionEnabled,
    isCurrentActionViewShown,
    normalizedPosterSrc,
    normalizedLoop,
    normalizedMuted,
    isContextMenuShown,
    isPlaybackBackgroundControlsShown,
    isLiveWithDVR,
    isLiveWithoutDVR,
    isPlayerOpenedInPageMode,
  };
}
