<template>
  <img
    data-image
    :width="width"
    :src="normalizedSrc"
    loading="lazy"
    :alt="alt"
    :class="{ [$style.fallback]: isFallback }"
    @error="handleError"
  />
</template>

<script lang="ts" setup>
import useCDNImage from '@package/content-utils/src/code/use-cdn-image';
import IconPhoto from '@SMART/assets/icons/51x51/placeholder.svg?inline';
import { computed, ref, watch } from 'vue';

interface Props {
  src?: string;
  alt?: string;
  width?: number;
  useFallbackIcon?: boolean;
}

const { src, useFallbackIcon = true, width = 200 } = defineProps<Props>();

const { getCDNLink, isCDNLink } = useCDNImage();

const hasError = ref(false);
const handleError = () => {
  hasError.value = true;
};

const isFallback = computed(() => {
  return (!src || hasError.value) && useFallbackIcon;
});

const normalizedSrc = computed(() => {
  if (isFallback.value) {
    return IconPhoto;
  }

  if (isCDNLink(src)) {
    return src;
  }

  return getCDNLink(src, width);
});

watch(
  () => src,
  () => (hasError.value = false),
);
</script>

<style module lang="scss">
@import '@/styles/mixins';

.fallback {
  align-self: center;
  width: adjustPx(44.891px) !important;
  height: adjustPx(44.891px) !important;
  border-radius: 0 !important;
  transform: none !important;
}
</style>
