<template>
  <playback-time-view
    :is-current-time-shown="isCurrentTimeShown"
    :is-duration-shown="false"
    :current-time-css-class-name="currentTimeCssClassName"
    :duration-css-class-name="durationCssClassName"
    :current-time="normalizedTime"
    :duration="normalizedDuration"
  />
</template>

<script lang="ts" setup>
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import PlaybackTimeView from './PlaybackTimeView.vue';

defineProps<{ durationCssClassName?: string; currentTimeCssClassName?: string }>();

const { customManifestTotalDuration } = storeToRefs(useManifestStore());
const { isLiveWithDVR, normalizedDuration, normalizedDisplayedCurrentTime, mediaSourceTechPlaybackType } =
  useVideoPlayerVariables();

const normalizedTime = computed(() => {
  if (normalizedDisplayedCurrentTime.value < 0) {
    return 0;
  }

  let time = 0;

  if (isLiveWithDVR.value && mediaSourceTechPlaybackType.value === 'hls.js') {
    time = normalizedDisplayedCurrentTime.value - customManifestTotalDuration.value;
  } else {
    time = normalizedDisplayedCurrentTime.value - normalizedDuration.value;
  }

  return Math.abs(time);
});

const isCurrentTimeShown = computed(() => {
  return true;
});
</script>
