import { Country, Year } from '@package/sdk/src/api';
import { Compilation } from '@package/sdk/src/api/compilations/compilation';
import { MediaContentType } from '@package/sdk/src/api/content/types/content-type';
import { Genre } from '@package/sdk/src/api/content/types/genre';
import { Media } from '@package/sdk/src/api/content/types/media';
import { Moment } from '@package/sdk/src/api/content/types/moment';

import { catalogService, playlistService } from '../services';
import { defineStore } from './utils/define-store';
import { getStoreProperty, VuexStoreNamespace } from './utils/namespaces';

export interface ContentState {
  _currentLinkedKinom?: Moment;
  _content?: Media;
  _genres: Genre[];
  _similar: Media[];
  _collections: Compilation[];
  _moments: Moment[];
  _countries: Country[];
  _periods: Year[];
  _isLoadingContent: boolean;
  _recommendations: Media[];
  _isAboutToFinish: boolean;
}

export interface ContentGetters {
  readonly currentLinkedKinom: Moment;
  readonly content: Media;
  readonly genres: Genre[];
  readonly countries: Country[];
  readonly periods: Year[];
  readonly similar: Media[];
  readonly collections: Compilation[];
  readonly moments: Moment[];
  readonly isLoadingContent: boolean;
  readonly recommendations: Media[];
  readonly subtitle: string;
  readonly subtitleGenres: string;
  readonly contentSubtitle: string;
  readonly limit: string;
  readonly isAboutToFinish: boolean;
}

export interface ContentActionsWithoutStoreArgument {
  setIsAboutToFinish(value: boolean): void;

  setCurrentLinkedKinom(moment?: Moment): void;

  setRecommendations(value: Media[]): void;

  fetchGenres(): Promise<void>;

  fetchCountries(): Promise<void>;
  fetchPeriods(): Promise<void>;

  fetchContent(options: {
    id: string;
    type: MediaContentType;
    headers?: Record<string, any>;
    query?: Record<string, any>;
  }): Promise<Media>;

  fetchSimilar(id: string): Promise<void>;

  fetchMoments(id: string): Promise<void>;

  fetchCollections(id: string): Promise<void>;

  reset(): void;
  resetContentData(): void;
}

export const useContentStore = defineStore<
  ContentState,
  ContentGetters,
  ContentActionsWithoutStoreArgument,
  VuexStoreNamespace.Content
>(VuexStoreNamespace.Content, {
  state: (): ContentState => ({
    _currentLinkedKinom: undefined,
    _content: undefined,
    _recommendations: [],
    _genres: [],
    _countries: [],
    _periods: [],
    _similar: [],
    _collections: [],
    _moments: [],
    _isLoadingContent: false,
    _isAboutToFinish: false,
  }),
  getters: {
    countries: (state) => state._countries,
    periods: (state) => state._periods,
    currentLinkedKinom: (state) => state._currentLinkedKinom,
    genres: (state) => state._genres,
    actors: (state) => state._content?.actors,
    content: (state) => state._content,
    similar: (state) => state._similar,
    collections: (state) => state._collections,
    moments: (state) => state._moments,
    subtitle: (state) => playlistService.getSubtitle(state._content?.genres || [], state._content),
    contentSubtitle: (state) => playlistService.getContentSubtitle(state._content),
    subtitleGenres: (state) => (state._content?.genres || [])?.map((genre) => genre.title).join(', '),
    limit: (state) => playlistService.getLimit(state._content?.ageLimit),
    isLoadingContent: (state) => state._isLoadingContent,
    recommendations: (state) => state._recommendations,
    isAboutToFinish: (state) => state._isAboutToFinish,
  },
  actions: {
    setIsAboutToFinish(store, value: boolean) {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), { _isAboutToFinish: value });
    },
    setCurrentLinkedKinom(store, moment?: Moment) {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), { _currentLinkedKinom: moment });
    },
    setRecommendations(store, recommendations: Media[]) {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), { _recommendations: recommendations });
    },
    async fetchCountries(store) {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), {
        _countries: await catalogService.fetchCountries(),
      });
    },
    async fetchPeriods(store) {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), {
        _periods: await catalogService.fetchPeriods(),
      });
    },
    async fetchGenres(store) {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), {
        _genres: await catalogService.fetchGenres(),
      });
    },
    async fetchContent(
      store,
      options: { id: string; type: MediaContentType; headers?: Record<string, any>; query: Record<string, any> },
    ) {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), { _isLoadingContent: true });

      const { id, type, headers, query } = options;

      if (type === MediaContentType.Movie) {
        const movie = await catalogService.fetchMovie(id, headers, query);
        store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), { _content: movie });
      }

      if (type === MediaContentType.Serial) {
        const serial = await catalogService.fetchSerial(id, headers, query);
        store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), { _content: serial });
      }

      const contentState = store.state[VuexStoreNamespace.Content]._content;

      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), { _isLoadingContent: false });

      return contentState;
    },
    async fetchSimilar(store, id: string): Promise<void> {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), {
        _similar: await catalogService.fetchSimilar(id),
      });
    },
    async fetchMoments(store, id: string) {
      try {
        store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), {
          _moments: await catalogService.fetchMoments(id),
        });
      } catch (error) {
        store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), { _moments: [] });
      }
    },
    async fetchCollections(store, id: string) {
      try {
        store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), {
          _collections: await catalogService.fetchCollections(id, { withContent: true }),
        });
      } catch (error) {
        store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), { _collections: [] });
      }
    },
    resetContentData(store) {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), {
        _currentLinkedKinom: undefined,
        _content: undefined,
        _recommendations: [],
        _similar: [],
        _collections: [],
        _isLoadingContent: false,
        _isAboutToFinish: false,
      });
    },
    reset(store) {
      store.commit(getStoreProperty(VuexStoreNamespace.Content, '$patch'), {
        _currentLinkedKinom: undefined,
        _content: undefined,
        _recommendations: [],
        _countries: [],
        _periods: [],
        _genres: [],
        _similar: [],
        _collections: [],
        _moments: [],
        _isLoadingContent: false,
        _isAboutToFinish: false,
      });
    },
  },
});
