<template>
  <div :class="$style.console">
    <div :class="$style.header">
      <span :class="$style.title">{{ $t('debug.terminal') }} - {{ $t('debug.terminal.console') }}</span>
      <app-checkbox v-model:input="isCurrentPlayerModel" :text="$t('debug.console.playerFilter')" />
      <app-input v-model:input="filter" type="text" :placeholder="$t('debug.filter')" />
      <app-button :on-click="onShowLogs" variation="button-secondary" :text="$t('debug.logs.hide')" />
    </div>
    <div :class="$style.body">
      <div v-for="log in messages" :key="log.date" :class="$style.message" :style="log.css">
        <span>{{ log.date }}: {{ log.levelStr }} - {{ log.message }} - {{ log.args.join(', ') }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import { KeyCode } from '@package/sdk/src/core';
import AppButton from '@PLAYER/player/components/ui/AppButton.vue';
import AppCheckbox from '@PLAYER/player/components/ui/AppCheckbox.vue';
import AppInput from '@PLAYER/player/components/ui/AppInput.vue';
import useKeyboardHandler from '@PLAYER/player/modules/hooks/use-keyboard-handler';
import useInstanceId from '@PLAYER/player/modules/instance/use-instance-id';
import { computed, onBeforeUnmount, ref } from 'vue';

const logger = useLogger('DebugInformationConsole.vue', 'media-player');
const keyboard = useKeyboardHandler();
const currentPlayerId = useInstanceId();

const emit = defineEmits<{
  (event: 'on-show-logs'): void;
}>();

const onShowLogs = () => {
  emit('on-show-logs');
};

const escapeHandler = keyboard.on(KeyCode.Escape, onShowLogs);

const filter = ref('');
const isCurrentPlayerModel = ref(true);

const logMessages = ref<unknown[]>([]);
const MAXIMUM_MESSAGES_SIZE = 1000;

const messages = computed(() => {
  return [];
});

onBeforeUnmount(() => {
  escapeHandler.dispose();
});
</script>

<style lang="scss" module>
.console {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  margin-right: auto;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}

@supports (position: sticky) {
  .header {
    position: sticky;
    top: -20px;
  }
}

.message {
  background-color: inherit !important;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
</style>
