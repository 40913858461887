import { QualityLevel } from '@PLAYER/player/modules/interfaces/hls';
import {
  MediaSourceTechEvent,
  MediaSourceTechEventManifestParsed,
} from '@PLAYER/player/tech/events/media-source-tech-event';
import AbstractMediaTech, {
  MediaSourceLoadOptions,
  MediaSourceTechBufferInfo,
} from '@PLAYER/player/tech/media-source-tech';
import loadDashJsModule from '@PLAYER/player/tech-loaders/dash-js-loader';
import type * as dashjs from 'dashjs';

export default class DashMediaTech extends AbstractMediaTech<HTMLVideoElement> {
  private tech: dashjs.MediaPlayerClass;

  private sourceLink = '';

  constructor() {
    super();
  }

  public get bandwidth(): number {
    return 0;
  }

  public get videoCodec(): string {
    return '';
  }

  public get audioCodec(): string {
    return '';
  }

  public get currentQualityLevelHeight(): number {
    return 0;
  }

  public get latency(): number {
    return 0;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    return { length: 0, start: 0 };
  }

  public async init(): Promise<void> {
    const DashModule = await loadDashJsModule();

    this.tech = DashModule().create();

    this.registerListeners();
  }

  public startLoad(offset = 0): void {
    this.tech.initialize(this.videoEl, this.sourceLink, false, offset);
  }

  public async attachMedia(element: HTMLVideoElement) {
    await super.attachMedia(element);
  }

  public async loadSource(options: MediaSourceLoadOptions) {
    const { src, offset } = options;

    this.sourceLink = src;
    this.tech.initialize(this.videoEl, src, true, offset);
  }

  public getNextLevel(): number {
    return this.tech.getQualityFor('video');
  }

  public setNextLevel(id: number): void {
    this.tech.setQualityFor('video', id);
  }

  protected registerListeners(): void {
    const onInitialized = () => {
      const bitrates = this.tech.getBitrateInfoListFor('video');

      const levels: QualityLevel[] = bitrates
        .map((level) => ({
          width: level.width,
          height: level.height,
          id: level.qualityIndex,
        }))
        .filter((level) => level.width > 0);

      this.emitter.emit(
        'manifest-parsed',
        new MediaSourceTechEvent<MediaSourceTechEventManifestParsed>({
          tech: 'dash.js',
          originalEvent: undefined,
          data: {
            qualityLevels: levels,
          },
        }),
      );
    };

    this.tech.on('streamInitialized', onInitialized);
  }

  public recoverMediaError(): void {
    //
  }

  public requestSaveMediaOffline(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public async stopLoad(): Promise<void> {
    //
  }

  public dispose() {
    super.dispose();

    this.tech.destroy();
  }
}
