<template>
  <section ref="container" :class="$style.wrapper">
    <h2 :class="$style.title">{{ title }}</h2>

    <div ref="seasonsContainer">
      <media-card-seasons-list
        :active-season="activeSeason"
        :seasons="items"
        @click="onSelectSeason"
        @active="handleActiveSeason"
      />
    </div>

    <div ref="episodesContainer">
      <ScrollViewport tag="ul" :class="$style.list" :x="offsetEpisodesLeftPx" role="list">
        <li v-for="(item, index) in currentSeason.episodes" :key="item.id" :class="$style.item">
          <label
            v-if="canWatchFreeEpisode && !isUnavailable(item)"
            :class="{ [$style.tag]: true, [$style.tagAvailable]: item.accessKind === ContentAccessTypes.AllUsers }"
          >
            <span v-if="item.accessKind === ContentAccessTypes.AllUsers">{{ $t('pages.mediaCard.watchNow') }}</span>
            <span v-else>
              {{ $t('pages.mediaCard.watchAfterSub') }}
            </span>
          </label>

          <NavigatableItem
            :class="$style.link"
            :tag="AppSlotButton"
            :data-testid="`episodes-${index + 1}`"
            :data-index="index"
            :disabled="isUnavailable(item)"
            @active="(element) => handleActiveEpisode(item, element as HTMLElement)"
            @click="onPlayEpisode(item, index)"
          >
            <app-image :src="item.preview || item.poster" :class="$style.preview" :width="550" />
            <div v-if="item.watchingItem?.offset" :class="$style.timelineBg">
              <div :class="$style.timeline" :style="{ width: getTimelineWidthInPercent(item) }"></div>
            </div>

            <p v-if="!isUnavailable(item)" :class="$style.duration">
              {{ formatDuration(item.duration, 'hhmm') }}
            </p>

            <p v-if="isUnavailable(item)" :class="$style.availability">
              {{ $t('pages.mediaCard.availability.unavailableItem') }}
            </p>
          </NavigatableItem>

          <section :class="$style.subtitle">
            <p>{{ item.title }}</p>
          </section>
        </li>
      </ScrollViewport>
    </div>
  </section>
</template>

<script setup lang="ts">
import * as playerHelpers from '@package/media-player/src/player/helpers';
import { type Episode, type Season, ContentAccessTypes } from '@package/sdk/src/api';
import { toPercent } from '@package/sdk/src/core';
import {
  formatDuration,
  SessionGetters,
  SessionState,
  storeToRefs,
  useSessionStore,
  useSliderOffset,
} from '@SMART/index';
import { computed, ref } from 'vue';

import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import MediaCardSeasonsList from '@/pages/media-card/components/MediaCardSeasonsList.vue';

interface Props {
  items: Season[];
  title: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'activated', offset: number, item: Episode): void;
  (event: 'selected', episode: Episode, episodeIndex: number, activeSeasonId: number): void;
}>();

const seasonsContainer = ref<HTMLElement>();

const container = ref<HTMLElement>();
const episodesContainer = ref<HTMLElement>();
const activeSeason = ref(0);
const { handleUpdateOffset: handleUpdateEpisodeOffset, offsetLeftPx: offsetEpisodesLeftPx } = useSliderOffset();
const { handleUpdateOffset: handleUpdateSeasonOffset, offsetLeftPx: offsetSeasonsLeftPx } = useSliderOffset();
const { isUnavailable, isAvailableSoon } = playerHelpers.useContentAvailability();

const { isActiveSubscription, isPartnerSubscription } = storeToRefs<SessionState, SessionGetters, unknown>(
  useSessionStore(),
);
const currentSeason = computed(() => props.items[activeSeason.value]);
const firstEpisode = computed(() => props.items[0].episodes[0]);
const hasFreeEpisode = computed(() => firstEpisode.value.accessKind === ContentAccessTypes.AllUsers);
const canWatchFreeEpisode = computed(
  () => !isActiveSubscription.value && !isPartnerSubscription.value && hasFreeEpisode.value,
);

const getTimelineWidthInPercent = (episode: Episode) => {
  const offset = episode.watchingItem?.offset;
  const isUnavailableEpisode = isUnavailable(episode) || isAvailableSoon(episode);

  if (!offset || isUnavailableEpisode) {
    return toPercent(0);
  }

  const percents = (offset / episode.duration) * 100;

  return toPercent(percents);
};

const handleActiveSeason = (options: { element: HTMLElement; index: number }) => {
  const { element, index } = options;

  handleUpdateSeasonOffset(
    '$el' in element ? (element.$el as HTMLElement) : element,
    seasonsContainer.value!.offsetWidth,
  );

  emit('activated', container.value?.offsetTop || 0, props.items[index]?.episodes[0]);

  offsetEpisodesLeftPx.value = 0;
  onSelectSeason(index);
};

const handleActiveEpisode = (item: Episode, element: HTMLElement) => {
  handleUpdateEpisodeOffset(
    '$el' in element ? (element.$el as HTMLElement) : element,
    episodesContainer.value!.offsetWidth,
  );
  emit('activated', container.value?.offsetTop || 0, item);
};

const onPlayEpisode = (episode: Episode, episodeIndex: number) => {
  emit('selected', episode, episodeIndex, activeSeason.value);
};

const onSelectSeason = (index: number) => (activeSeason.value = index);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.title {
  margin-bottom: adjust.adjustPx(24px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.list {
  display: flex;
  height: 200px;
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: adjust.adjustPx(32px);
  width: adjust.adjustPx(480px);
  min-width: adjust.adjustPx(480px);
  height: adjust.adjustPx(280px);
  min-height: adjust.adjustPx(280px);
  border-radius: adjust.adjustPx(24px);
  background-color: var(--color-bg-tertiary);
  outline: none;

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }

  .subtitle {
    position: absolute;
    top: adjust.adjustPx(284px);
    left: adjust.adjustPx(8px);
    display: -webkit-box;
    overflow: hidden;
    color: var(--color-notheme-text-primary);
    text-align: start;
    min-width: adjust.adjustPx(480px);
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @include smartTvFonts.SmartTvBody-1();
  }
}

.availability {
  position: absolute;
  left: 0;
  right: 0;
  z-index: map-get($map: $layers, $key: --z-index-heading);
  width: 100%;
  color: var(--color-text-secondary);
  margin-inline: auto;
  text-align: center;

  @include smartTvFonts.SmartTvBody-2();
}

.duration {
  position: absolute;
  bottom: adjust.adjustPx(30px);
  right: adjust.adjustPx(24px);
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvBody-3();
}

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: adjust.adjustPx(24px);
  overflow: hidden;
  outline: none;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: adjust.adjustPx(24px);
    content: '';
    background: var(--color-notheme-dim-black-40);
  }

  &:hover::after,
  &.active::after {
    box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-bg-accent);
  }
}

.timeline {
  position: absolute;
  z-index: 2;
  height: adjust.adjustPx(6px);
  border-radius: adjust.adjustPx(4px);
  background-color: var(--color-notheme-bg-accent);
}
.timelineBg {
  position: absolute;
  bottom: adjust.adjustPx(20px);
  left: adjust.adjustPx(26px);
  right: adjust.adjustPx(26px);
  height: adjust.adjustPx(6px);
  border-radius: adjust.adjustPx(4px);
  background-color: var(--color-bg-ghost);
}

.tag {
  position: absolute;
  top: adjust.adjustPx(16px);
  right: adjust.adjustPx(16px);
  z-index: 99;
  padding: adjust.adjustPx(16px) adjust.adjustPx(24px) adjust.adjustPx(16px) adjust.adjustPx(24px);
  width: fit-content;
  height: adjust.adjustPx(72px);
  border-radius: adjust.adjustPx(12px);
  background: var(--color-notheme-dim-black-60);
  color: var(--color-notheme-text-primary);
  white-space: nowrap;

  @include smartTvFonts.SmartTvLabel-3();

  &Available {
    color: var(--color-notheme-text-action);
  }
}
</style>
