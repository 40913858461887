import { Disposable, EventEmitter } from '@package/sdk/src/core';

import { environmentService } from '..';

interface LifecycleEventMap {
  'will-shutdown': void;
}

const isClient = typeof window !== 'undefined';

export class LifecycleService extends Disposable {
  private emitter: EventEmitter<LifecycleEventMap> = new EventEmitter<LifecycleEventMap>();

  private isEnabled = true;

  constructor() {
    super();

    if (isClient) {
      this.registerListeners();
    }
  }

  public onWillShutdown(callback: VoidFunction) {
    return this.emitter.on('will-shutdown', callback);
  }

  private registerListeners() {
    if (!this.isEnabled) {
      return;
    }

    window.addEventListener('beforeunload', (event) => {
      const isDev = environmentService.getVariable('isDev');

      if (isDev) {
        return;
      }

      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;

      this.emitter.emit('will-shutdown');
    });
  }
}
