<template>
  <root-video-z-index-layer :class="$style.kinomPreview" fullscreen>
    <app-image
      v-if="(content as Moment).preview"
      :image-class="$style.image"
      :src="(content as Moment).preview"
      :alt="content.title"
      :width="$isMobile ? 370 : 760"
    />
  </root-video-z-index-layer>
</template>

<script setup lang="ts">
import type { Moment } from '@package/sdk/src/api';
import AppImage from '@PLAYER/player/components/ui/AppImage.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';

const { content } = useContentInformation();
</script>

<style module lang="scss">
.kinomPreview {
  z-index: var(--z-index-kinom-preview-layer);
  background-color: var(--color-notheme-bg-banner-button-pressed);
}

.image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: var(--g-border-round-24);
  transform: var(--g-player-default-transform-scale);
  user-select: none;
  object-fit: contain;
}
</style>
