import { getFirstElement } from '@package/sdk/src/core';
import type {
  MediaSourceTechEventError,
  MediaSourceTechEventManifestParsed,
  MediaSourceTechEventQualityLevelSwitched,
} from '@PLAYER/player/tech/events/media-source-tech-event';
import { MediaSourceTechEvent } from '@PLAYER/player/tech/events/media-source-tech-event';
import AbstractMediaTech, {
  MediaSourceLoadOptions,
  MediaSourceTechBufferInfo,
} from '@PLAYER/player/tech/media-source-tech';
import loadShakaPlayerModule from '@PLAYER/player/tech-loaders/shaka-player-loader';
import { isNumber } from '@vueuse/core';

export default class ShakaPlayerMediaTech extends AbstractMediaTech<HTMLVideoElement> {
  private tech: shaka.Player;

  private manifestUrl: string;

  constructor() {
    super();
  }

  public get shakaInstance(): shaka.Player {
    return this.tech;
  }

  public get audioCodec(): string {
    if (!this.tech) {
      return '';
    }

    const audioCodec = getFirstElement(this.tech.getVariantTracks())?.audioCodec;

    if (!audioCodec) {
      return '';
    }

    return audioCodec;
  }

  public get videoCodec(): string {
    if (!this.tech) {
      return '';
    }

    const videoCodec = getFirstElement(this.tech.getVariantTracks())?.videoCodec;

    if (!videoCodec) {
      return '';
    }

    return videoCodec;
  }

  public get bandwidth(): number {
    if (!this.tech) {
      return 0;
    }

    const { estimatedBandwidth } = this.tech.getStats();

    return estimatedBandwidth / 1024;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    const defaultBufferRange: MediaSourceTechBufferInfo = {
      length: 0,
      start: 0,
    };

    if (!this.tech) {
      return defaultBufferRange;
    }

    const { video } = this.tech.getBufferedInfo();

    const bufferedRange = getFirstElement(video);

    if (!bufferedRange) {
      return defaultBufferRange;
    }

    const { start, end } = bufferedRange;

    return {
      start,
      length: end - start,
    };
  }

  public get currentQualityLevelHeight(): number {
    if (!this.tech) {
      return 0;
    }

    const { height } = this.tech.getStats();

    return height;
  }

  public getNextLevel(): number {
    return 0;
  }

  public async init(): Promise<void> {
    const ShakaModule = await loadShakaPlayerModule();

    this.tech = new ShakaModule.Player();

    this.registerListeners();
  }

  public get latency(): number {
    if (!this.tech) {
      return 0;
    }

    const { loadLatency } = this.tech.getStats();

    return loadLatency;
  }

  public async attachMedia(element: HTMLVideoElement): Promise<void> {
    await this.tech.attach(element);
  }

  public async loadSource(options: MediaSourceLoadOptions): Promise<void> {
    const { src } = options;

    this.manifestUrl = src;

    await this.tech.load(src);
  }

  public recoverMediaError(): void {
    this.tech.retryStreaming();
  }

  public setNextLevel(levelId: number): void {
    const levels = this.tech.getVariantTracks();

    if (levelId === -1) {
      this.tech.configure({ abr: { enabled: true } });
      return;
    }

    const currentLevel = levels.find((level) => level.id === levelId);

    if (!currentLevel) {
      return;
    }

    // Говорим shaka, что не нужно автоматически искать качество получше
    this.tech.configure({ abr: { enabled: false } });
    this.tech.selectVariantTrack(currentLevel, true, 5);
  }

  public startLoad(_: number | undefined): void {}

  public async stopLoad(): Promise<void> {
    this.tech.destroyAllPreloads();
    await this.tech.unload();
  }

  public async requestSaveMediaOffline(): Promise<void> {
    const metadata = {
      title: '',
      downloaded: new Date(),
    };

    const shaka = await loadShakaPlayerModule();

    const storage = new shaka.offline.Storage(this.tech);

    function selectTracks(tracks: shaka.extern.Track[]) {
      // This example stores the highest bandwidth variant.
      //
      // Note that this is just an example of an arbitrary algorithm, and not a best
      // practice for storing content offline.  Decide what your app needs, or keep
      // the default (user-pref-matching audio, best SD video, all text).
      const found = tracks
        .filter(function (track) {
          return track.type == 'variant';
        })
        .sort(function (a, b) {
          return a.bandwidth - b.bandwidth;
        })
        .pop();

      return [found];
    }

    function setDownloadProgress(_: any, progress: any) {
      console.info(progress);
    }

    storage.configure({
      offline: {
        progressCallback: setDownloadProgress,
        trackSelectionCallback: selectTracks,
      },
    });

    storage.store(this.manifestUrl, metadata);
  }

  protected registerListeners(): void {
    const onError = (error: Event) => {
      const normalizedError = error as unknown as shaka.util.Error;

      // Ошибка не shaka
      if (!normalizedError.severity || normalizedError.severity == 2.0) {
        return this.emitter.emit(
          'error',
          new MediaSourceTechEvent<MediaSourceTechEventError>({
            tech: 'shaka',
            originalEvent: error,
            data: {
              fatal: true,
              errorType: 'manifest-parsing-error',
            },
          }),
        );
      }

      this.emitter.emit(
        'error',
        new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'shaka',
          originalEvent: error,
          data: {
            fatal: false,
            errorType: 'buffer-error',
          },
        }),
      );
    };

    const onManifestParsed = (event: Event) => {
      const levels = this.tech
        .getVariantTracks()
        .filter((level) => isNumber(level.width) && isNumber(level.height) && isNumber(level.id))
        .sort((a, b) => (a.height as number) - (b.height as number));

      this.emitter.emit(
        'manifest-parsed',
        new MediaSourceTechEvent<MediaSourceTechEventManifestParsed>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            qualityLevels: levels.map((level) => ({
              width: level.width as number,
              height: level.height as number,
              id: level.id,
            })),
          },
        }),
      );
    };

    const onQualityLevelSwitched = (event: Event) => {
      const levels = this.tech.getVariantTracks();

      const currentLevel = levels.find((level) => level.active);

      if (!currentLevel) {
        return;
      }

      this.emitter.emit(
        'quality-level-switched',
        new MediaSourceTechEvent<MediaSourceTechEventQualityLevelSwitched>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            level: currentLevel.id,
          },
        }),
      );
    };

    const onStallDetected = (event: Event) => {
      this.emitter.emit(
        'error',
        new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            fatal: false,
            errorType: 'buffer-error',
          },
        }),
      );
    };

    // Когда проигрывание вдруг застопорилось (упрерлись в буффер), одна дальше играть можем
    this.tech.addEventListener('stalldetected', onStallDetected);
    // Ошибка, может критичная, а может и нет. UI плеера разберется
    this.tech.addEventListener('error', onError);
    // Как только манифест распаршен. Есть еще событие manifestparsed, одна shaka рекомендует это событие.
    this.tech.addEventListener('streaming', onManifestParsed);
    // Если качество поменялось автоматически, из-за качества интернета
    this.tech.addEventListener('adaptation', onQualityLevelSwitched);
    // Если мы сами (руками) поменяли качество в плеере
    this.tech.addEventListener('variantchanged', onQualityLevelSwitched);
  }

  public dispose() {
    super.dispose();
    this.tech.destroy();
  }
}
