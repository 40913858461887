import { ENDPOINTS } from '@package/sdk/src/api/endpoints';

import { ApiError } from '../api-error';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';

export class ParentalService {
  constructor(private readonly $http: RequestService) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public async createControl(code: string | number, ageLimit: string | number): Promise<boolean> {
    try {
      await this.$http.request(
        {
          method: HTTPRequestMethod.Post,
          url: ENDPOINTS.PARENTAL_CONTROL,
          query: {
            code,
            age_limit: ageLimit,
          },
        },
        { withToken: true, transformResult: true },
      );
      return true;
    } catch (e) {
      throw new ApiError(e as Error);
    }
  }

  public updateControl(code: string | number, ageLimit: string | number): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Put,
        url: ENDPOINTS.PARENTAL_CONTROL,
        query: {
          code,
          age_limit: ageLimit,
        },
      },
      { withToken: true, transformResult: true },
    );
  }

  public setChildrenAccess(code?: string | number): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Post,
        url: ENDPOINTS.PARENTAL_SET_CHILDREN_ACCESS,
        query: {
          code,
        },
      },
      { withToken: true, transformResult: true },
    );
  }

  public updateChildrenAccess(code: string | number): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Put,
        url: ENDPOINTS.PARENTAL_CONTROL,
        query: {
          code,
          age_limit: 18,
        },
      },
      { withToken: true, transformResult: true },
    );
  }

  public resetChildrenAccess(): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Post,
        url: ENDPOINTS.PARENTAL_SET_NO_CHILDREN_ACCESS,
      },
      { withToken: true, transformResult: true },
    );
  }

  public deleteControl(): Promise<unknown> {
    return this.$http.request(
      {
        method: HTTPRequestMethod.Delete,
        url: ENDPOINTS.PARENTAL_CONTROL,
      },
      { withToken: true, transformResult: true },
    );
  }

  public async checkControl(code: string | number): Promise<boolean> {
    try {
      await this.$http.request(
        {
          method: HTTPRequestMethod.Get,
          url: ENDPOINTS.PARENTAL_CONTROL_CHECK_CODE,
          query: {
            code,
          },
        },
        { withToken: true, transformResult: true },
      );

      return true;
    } catch (error) {
      throw new ApiError(error as Error);
    }
  }
}
