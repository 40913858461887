import { SmartTvVijuPlayer } from '@package/media-player/src/player';
import type { PlayerInstanceConstructorOptions } from '@package/media-player/src/player/modules/instance/interfaces';
import { FeatureToggle } from '@package/sdk/src/api';
import { analyticService, environmentService, featuresService, usePlayerSentryError } from '@SMART/index';
import { onBeforeUnmount } from 'vue';

export default function useSmartTVPlayer(options: PlayerInstanceConstructorOptions) {
  const { startRecordingPlayerExceptions } = usePlayerSentryError();

  const defaultOptions: Partial<PlayerInstanceConstructorOptions> = {
    muted: false,
    autoplay: true,
  };

  const _options = {
    ...defaultOptions,
    ...options,
  };

  const player = new SmartTvVijuPlayer(_options);

  const isRelease = environmentService.getVariable<boolean>('isRelease');
  const isDev = environmentService.getVariable<boolean>('isDev');

  startRecordingPlayerExceptions(player);

  const isShakaApiEnabled = (() => {
    if (isDev) {
      return true;
    }

    return featuresService.getFeatureFlag(FeatureToggle.ShakaPlayerApiEnabled).status;
  })();

  player.setConfigProperties({
    'experimental.feature.shakaPlayerAPI': isShakaApiEnabled,
    'system.isDebugEnabled': !isRelease,
    'analytic.metadata': { page: analyticService.getAnalyticPageName() },
  });

  onBeforeUnmount(() => {
    player.endMediaSession();
  });

  return player;
}
