<template>
  <div :class="$style.wrapper">
    <div v-if="(!currentActiveBeltItem.hls || !isPlaying) && previewSrcDebounced" :class="$style.preview">
      <app-image :use-fallback-icon="false" :src="previewSrcDebounced" :width="1000" />
    </div>

    <div v-show="currentActiveBeltItem.hls" ref="playerEl" :class="$style.player" />

    <TopHeader
      v-if="isVNodeMounted"
      :title="currentActiveBeltItem.title"
      :subtitle="currentActiveBeltItem.subtitle"
      :limit="currentActiveBeltItem.limit"
      :rest-of-time="currentActiveBeltItem.restOfTime"
      :class="$style.header"
    />

    <section ref="content" :class="$style.content">
      <div ref="el">
        <PlaylistSlider
          v-for="(block, index) in normalizedBlocks"
          :key="block.id"
          :block="block"
          :recommendations="recommendations as Media[]"
          :watching-items="watchingItemsV2 as Media[]"
          :channels="channels as Channel[]"
          :row-index="index"
          :wrapper="content"
          @vue:mounted="onVNodeMounted"
          @activated="(item, value) => activateItem(item, value, index)"
          @update:watching-items="onUpdateWatchingItems"
        />
      </div>

      <section :class="$style.stub"></section>
    </section>

    <MyChannelModal v-if="shouldShowMyChannel" @finish="finishMyChannel" />
  </div>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/smart-tv-constants-instance';
import useLogger from '@package/logger/src/use-logger';
import { useMainPageAnalytics } from '@package/sdk/src/analytics';
import type { Channel, ContentMoment, Episode, GenresBeltItem, Media } from '@package/sdk/src/api';
import { DisplayType, MediaContentType } from '@package/sdk/src/api';
import {
  DisposableStore,
  indexOutOfRange,
  onResetIdle,
  timeout,
  TvKeyCode,
  UnexpectedComponentStateError,
} from '@package/sdk/src/core';
import { useLazyLoadingBlocks } from '@package/smarttv-base/src/utils/use-lazy-loading-blocks';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  alertService,
  analyticService,
  catalogService,
  channelsService,
  ContentGetters,
  ContentState,
  deviceService,
  FocusKeys,
  keyboardEventHandler,
  MainPageGetters,
  MainPageState,
  onboardingService,
  OperationSystem,
  playerToRefs,
  playlistService,
  RouterPage,
  routerService,
  SessionGetters,
  SessionState,
  storeToRefs,
  translate,
  TvChannelGetters,
  TvChannelState,
  useContentStore,
  useMainPageStore,
  useSessionStore,
  useTvChannelsStore,
} from '@SMART/index';
import { refDebounced, watchDebounced } from '@vueuse/core';
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted, provide, reactive, ref } from 'vue';

import AppImage from '@/components/app-image/AppImage.vue';
import MyChannelModal from '@/components/my-channel-modal/MyChannelModal.vue';
import PlaylistSlider from '@/components/playlist-slider/PlaylistSlider.vue';
import TopHeader from '@/components/top-header/TopHeader.vue';
import useSmartTVPlayer from '@/sdk/player/use-smarttv-player';
import useSessionVariables from '@/sdk/session/use-session-variables';

const mainPageStore = useMainPageStore();
const contentStore = useContentStore();

const logger = useLogger('MainPage.vue');

const { recommendations, genres } = storeToRefs<ContentState, ContentGetters, unknown>(contentStore);
const { watchingItemsV2, _blocks, selectedBeltItem, profileType } = storeToRefs<
  MainPageState,
  MainPageGetters,
  unknown
>(mainPageStore);
const { profile, isChildProfileSet, isActiveSubscription } = storeToRefs<SessionState, SessionGetters, unknown>(
  useSessionStore(),
);
const { channels } = storeToRefs<TvChannelState, TvChannelGetters, unknown>(useTvChannelsStore());
const { isAuth } = useSessionVariables();

const { el, focusSelf, focusKey } = useNavigatable({
  focusKey: FocusKeys.MAIN_PAGE,
  saveLastFocusedChild: true,
});
provide('parentFocusKey', focusKey.value);

const parsePlaylistKey = (key?: string) => {
  const target = key ?? SpatialNavigation.getCurrentFocusKey();
  const [_, playlistIndex, index, id] = target.split(':');

  return {
    row: parseInt(playlistIndex),
    col: parseInt(index),
    id,
  };
};

const { onVNodeFocused, normalizedBlocks } = useLazyLoadingBlocks({
  items: _blocks,
});

const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({ withTimeout: true });

const mainPageAnalytics = useMainPageAnalytics(analyticService.sender);

const playerEl = ref<HTMLElement>();
const content = ref<HTMLElement>();

let page = 2;

const isContentLoaded = ref(false);

const previewSrc = ref('');
const previewSrcDebounced = refDebounced(previewSrc, 500);

const currentActiveBeltItem = reactive({
  title: '',
  hls: '',
  restOfTime: 0,
  subtitle: '',
  limit: '',
});

const shouldShowMyChannel = ref(false);
const activeItem = ref<ContentMoment | GenresBeltItem | Media | Channel>();

const disposableStore = new DisposableStore();

const player = useSmartTVPlayer({
  projector: 'kinom',
  muted: false,
  autoplay: true,
});

disposableStore.add(player);

const { isPlaying, isVideoEnded } = playerToRefs(player);

const setActiveNextPlaylistItem = async (): Promise<void> => {
  const { row, col, id } = parsePlaylistKey();

  // do not switch to the next element if the focus is not on the playlist
  if (indexOutOfRange(row)) {
    return;
  }

  const nextInRowFocusKey = FocusKeys.PLAYLIST_ITEM(row, col + 1);

  if (SpatialNavigation.doesFocusableExist(nextInRowFocusKey)) {
    SpatialNavigation.setFocus(nextInRowFocusKey);
  }
};

const onPlayerEnded = async () => {
  isPlaying.value = true;

  await setActiveNextPlaylistItem();
};

const activateItem = async (item: ContentMoment | GenresBeltItem | Media | Channel, _: number, index: number) => {
  let previewLink = '';

  onVNodeFocused(index);

  isPlaying.value = false;
  isVideoEnded.value = false;
  activeItem.value = item;

  player.pause();
  player.stopLoad();

  currentActiveBeltItem.restOfTime = 0;
  currentActiveBeltItem.title = '';
  currentActiveBeltItem.limit = '';
  currentActiveBeltItem.subtitle = playlistService.getSubtitle(genres.value, item as Media);

  if ('title' in item) {
    currentActiveBeltItem.title = item.title;
  }

  if ('contentTitle' in item) {
    currentActiveBeltItem.title = item.contentTitle;
  }

  currentActiveBeltItem.hls = 'hls' in item ? item.hls : '';

  if ('background' in item) {
    previewLink = item.background ?? '';
  }

  const channel = item as Channel;

  if (channel?.currentProgram) {
    previewLink = channel.currentProgram.background ?? previewLink ?? '';
    currentActiveBeltItem.title = channel.currentProgram.title || '';
    currentActiveBeltItem.subtitle = playlistService.getSubtitle(genres.value, {
      duration: channel.currentProgram.duration,
      countries: channel.currentProgram.countries,
      genre: channel.currentProgram.genre,
      contentType: channel.currentProgram.contentType as MediaContentType,
      seasonNumber: channel.currentProgram?.seasonNumber,
    });

    currentActiveBeltItem.limit = playlistService.getLimit(channel.currentProgram.ageLimit ?? 0);

    if (!channel.currentProgram.genre?.length) {
      currentActiveBeltItem.subtitle = '';
    }
  }

  if ('ageLimit' in item) {
    currentActiveBeltItem.limit = playlistService.getLimit(item.ageLimit ?? 0);
  }

  if ('poster' in item && item.poster) {
    previewLink = item.poster ?? '';
  }

  if ('smartTvBackground' in item) {
    previewLink = (item.smartTvBackground as string) ?? '';
  }

  if ('primaryContent' in item) {
    currentActiveBeltItem.subtitle = playlistService.getSubtitle(genres.value, item.primaryContent);
    currentActiveBeltItem.limit = playlistService.getLimit(item?.primaryContent?.ageLimit ?? 0);
  }

  if ('name' in item) {
    currentActiveBeltItem.title = translate('pages.main.moodHeading');
    currentActiveBeltItem.subtitle = '';
    currentActiveBeltItem.limit = '';
  }

  const episode = item as Episode;

  if (episode.serialTitle) {
    currentActiveBeltItem.title = episode.serialTitle;
  }

  previewSrc.value = previewLink;

  if (index >= ConstantsConfigInstanceSmartTV.getProperty('loadNextPageOffset') && !isContentLoaded.value && page) {
    const blocksData = await playlistService.fetchBlocks({ page });

    const updatedBlocks = [..._blocks.value, ...blocksData];

    mainPageStore.setBlocks(updatedBlocks);

    isContentLoaded.value = Boolean(blocksData.length < ConstantsConfigInstanceSmartTV.getProperty('mainBlockSize'));
    page++;
  }

  if (!page) {
    page =
      Math.ceil(
        (_blocks.value.length - (watchingItemsV2.value.length ? 1 : 0)) /
          ConstantsConfigInstanceSmartTV.getProperty('mainBlockSize'),
      ) + 1;
  }
};

const onChangeActiveItem = async (item?: ContentMoment | GenresBeltItem | Media | Channel) => {
  if (!item) {
    return;
  }

  if (!currentActiveBeltItem.hls) {
    return player.pause();
  }

  player.stopLoad();

  player.setConfigProperty('content.media', item);

  /**
   * TODO: Пока криво написан код - будет переделан позже, тестируем поведение на SmartTV
   */
  player.load({ src: currentActiveBeltItem.hls, id: item.id, offset: 0, autoplay: false });
  await timeout(100);
  player.pause();
  player.setQuality('lowest');
  await timeout(500);
  player.play();
};

watchDebounced(activeItem, onChangeActiveItem, { immediate: true, debounce: 2000 });

let idleListener: () => void;

const finishMyChannel = async () => {
  shouldShowMyChannel.value = false;
  await nextTick();
  player.play({ manual: true });
};

const fetchRecommendations = async () => {
  try {
    return isAuth.value
      ? await catalogService.fetchPersonalRecommendations()
      : await catalogService.fetchColdRecommendations();
  } catch (error) {
    logger.error(error);
    return [];
  }
};

const loadMainPageData = async () => {
  mainPageStore.setWatchingItemsV2([]);
  mainPageStore.setBlocks([]);

  const [watchingData, blocksData] = await Promise.all([
    isAuth.value ? catalogService.fetchContinueWatchItemsV2() : Promise.resolve([] as Media[]),
    playlistService.fetchBlocks({ page: 1 }),
  ]);
  mainPageStore.setWatchingItemsV2(watchingData);

  const watchingBlock = {
    displayType: DisplayType.ContinueWatch,
    id: 'ContinueWatch',
    beltItems: [],
    contentMomentsList: [],
    offerId: '',
    playlistId: '',
    playlistSlug: '',
    position: 0,
    texts: {},
    title: translate('pages.main.continueWatch'),
  };

  const oldProfile = profileType?.value;
  const newProfile = profile?.value?.kind;

  if (_blocks.value.length && oldProfile === newProfile) {
    let filteredBlocks = _blocks.value.filter((block) => block.displayType !== DisplayType.ContinueWatch);

    filteredBlocks = watchingData.length ? [watchingBlock, ...filteredBlocks] : filteredBlocks;

    mainPageStore.setBlocks(filteredBlocks);

    page = 0;
    return;
  }

  mainPageStore.setProfileType(newProfile);

  const filteredBlocks = watchingData.length ? [watchingBlock, ...blocksData] : blocksData;

  mainPageStore.setBlocks(filteredBlocks);

  window.setTimeout(async () => {
    const recommendationsData = await fetchRecommendations();
    contentStore.setRecommendations(recommendationsData);
    await channelsService.fetchChannels();
  }, 1000);
};

const onUpdateWatchingItems = async (activeFocusKey: string) => {
  mainPageStore.setWatchingItemsV2(await catalogService.fetchContinueWatchItemsV2());

  await nextTick();

  // const [playlist, playlistIndex, index] = activeFocusKey.split(':');
  // const prevFocusKey = [playlist, playlistIndex, parseInt(index) - 1].join(':')
  // const nextFocusKey = [playlist, playlistIndex, parseInt(index) + 1].join(':')
};

const onPlayerMounted = async () => {
  await nextTick();

  shouldShowMyChannel.value ? player.pause({ manual: false }) : player.play({ manual: false });
};

player.on('ended', onPlayerEnded);
player.on('mounted', onPlayerMounted);

onBeforeMount(() => {
  if (deviceService.os === OperationSystem.Desktop) {
    new PerformanceObserver((entryList) => {
      for (const entry of entryList.getEntriesByName('first-contentful-paint')) {
        logger.info('FCP candidate:', entry.startTime, entry);
      }
    }).observe({ type: 'paint', buffered: true });

    new PerformanceObserver((entryList) => {
      for (const entry of entryList.getEntries()) {
        logger.info('LCP candidate:', entry.startTime, entry);
      }
    }).observe({ type: 'largest-contentful-paint', buffered: true });
  }

  if (
    isAuth.value &&
    isActiveSubscription.value &&
    !isChildProfileSet.value &&
    !onboardingService.isParentalCodeFinished()
  ) {
    routerService.push({ name: RouterPage.ParentalPage });
  }
});

onMounted(async () => {
  mainPageAnalytics.onShowMainPage();

  try {
    await loadMainPageData();

    mainPageAnalytics.onDsmlRecommendationsBlockDisplayed();
  } catch (error) {
    logger.error(error);
  }

  disposableStore.add(keyboardEventHandler.on(TvKeyCode.PLAY, () => player.play({ manual: true })));
  disposableStore.add(keyboardEventHandler.on(TvKeyCode.PAUSE, () => player.pause({ manual: true })));
  disposableStore.add(keyboardEventHandler.on(TvKeyCode.STOP, () => player.pause({ manual: true })));

  let itemIndex = watchingItemsV2.value.findIndex((x: Media) => x.id === selectedBeltItem.value.id);

  if (itemIndex < 0) {
    itemIndex = selectedBeltItem.value.index || 0;
  }

  if (!playerEl.value) {
    logger.error(new UnexpectedComponentStateError('playerEl'));
  } else {
    player.mount(playerEl.value);
  }

  if (!onboardingService.isMyChannelFinished()) {
    shouldShowMyChannel.value = true;
    player.pause();
  }

  player.play();

  focusSelf();
});

onBeforeUnmount(() => {
  disposableStore.dispose();

  if (idleListener) {
    onResetIdle(idleListener);
  }
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@/styles/layers.scss' as layers;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.preview {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(1.45, 1.45);
}

.preview:after,
.player:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    #091e1e 0%,
    rgba(9, 30, 30, 0.95) 20.83%,
    rgba(9, 30, 30, 0.6) 74.27%,
    rgba(9, 30, 30, 0) 100%
  );
  content: '';
}

.header {
  margin-bottom: adjust.adjustPx(40px);
  padding-left: adjust.adjustPx(188px);
  padding-right: adjust.adjustPx(60px);

  h1 {
    max-width: unset;
  }
}

.content {
  position: relative;
  z-index: map-get($map: layers.$layers, $key: --z-index-content);
  width: calc(100% - adjust.adjustPx(188px));
  height: adjust.adjustPx(594px);
  margin-left: adjust.adjustPx(188px);
  overflow: hidden;
  scroll-padding-top: adjust.adjustPx(60px);
}

.stub {
  height: adjust.adjustPx(427px);
  margin-bottom: adjust.adjustPx(100px);
}
</style>
