<template>
  <teleport to="#app">
    <section v-if="!isModalHidden" ref="el" :class="$style.shuffleModal">
      <header :class="$style.header">
        <NavigatableItem
          :active-class="$style.active"
          :class="$style.closeButton"
          :tag="AppButton"
          transition-duration="0.1s"
          @click="emit('close')"
        >
          <template #icon>
            <IconClose />
          </template>
        </NavigatableItem>

        <span :class="$style.title">{{ $t('pages.catalog.contentShuffleTitle') }}</span>

        <NavigatableItem
          navigation-key="next"
          :tag="AppButton"
          :class="$style.nextButton"
          :active-class="$style.active"
          :text="$t('pages.catalog.next')"
          @click="loadContent"
        />
      </header>

      <ShuffleCard :class="$style.content" v-bind="cardContent" :disabled="false" />
    </section>
  </teleport>
</template>

<script setup lang="ts">
import { CollectionContentType, MediaContentType } from '@package/sdk/src/api';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import IconClose from '@SMART/assets/icons/33x33/close.svg';
import {
  catalogService,
  ContentGetters,
  ContentState,
  FocusKeys,
  playlistService,
  RouterPage,
  storeToRefs,
  useCatalogStore,
  useContentStore,
} from '@SMART/index';
import { nextTick, onMounted, onUnmounted, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ShuffleCard from '@/pages/catalog/components/ShuffleCard.vue';

interface Props {
  contentType: MediaContentType;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.SHUFFLE_MODAL,
  isFocusBoundary: true,
  preferredChildFocusKey: FocusKeys.SELECT_MEDIA_BUTTON,
});
provide('parentFocusKey', focusKey.value);

const { genres } = storeToRefs<ContentState, ContentGetters, unknown>(useContentStore());

const route = useRoute();

// Content

interface CardContent {
  id: string;
  contentType: CollectionContentType | MediaContentType;
  title: string;
  subtitle: string;
  description: string;
  limit: string;
  poster: string;
}

const catalogStore = useCatalogStore();

const cardContent = ref<CardContent>({
  id: '',
  contentType: MediaContentType.All,
  title: '',
  subtitle: '',
  description: '',
  limit: '',
  poster: '',
});

const loadContent = async () => {
  let resolvedContentType: MediaContentType | CollectionContentType = props.contentType;

  if (props.contentType === MediaContentType.ALL) {
    resolvedContentType = randomContentType.value;
    randomContentType.value =
      randomContentType.value === MediaContentType.Movie ? MediaContentType.Serial : MediaContentType.Movie;
  }

  const data = await catalogService.fetchContentRecommendationsRandom(resolvedContentType as MediaContentType);

  catalogStore.updateSelectedItem(null);

  cardContent.value = {
    id: data.id,
    contentType: data.contentType,
    title: data.title,
    subtitle: playlistService.getSubtitle(genres.value, data),
    description: data.description,
    limit: playlistService.getLimit(data.ageLimit),
    poster: data.poster,
  };
};

const isModalHidden = ref(false);

watch(
  () => route.name,
  async () => {
    isModalHidden.value = route.name !== RouterPage.CatalogPage;

    if (!isModalHidden.value) {
      await nextTick();
      focusSelf();
    }
  },
);

const randomContentType = ref<MediaContentType.Movie | MediaContentType.Serial>(MediaContentType.Movie);

onMounted(async () => {
  await loadContent();
  focusSelf();
});

onUnmounted(async () => {
  SpatialNavigation.setFocus(FocusKeys.CATALOG_SHUFFLE_BUTTON);
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.shuffleModal {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  border-radius: adjust.adjustPx(40px) adjust.adjustPx(40px) 0px 0px;
  background-color: var(--color-bg-modal);
  flex-direction: column;

  padding: {
    top: adjust.adjustPx(40px);
    left: adjust.adjustPx(40px);
    right: adjust.adjustPx(40px);
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: adjust.adjustPx(40px);

  .title {
    @include smartTvFonts.SmartTvTitle-3();
  }

  .closeButton {
    position: absolute;
    left: 0;
    width: adjust.adjustPx(88px);
    height: adjust.adjustPx(88px);
  }

  .nextButton {
    position: absolute;
    right: 0;
  }
}

.active {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}

.content {
  display: flex;
  width: 100%;
  flex-grow: 1;
  margin-bottom: adjust.adjustPx(40px);
}
</style>
